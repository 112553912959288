<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="cryoscopy-ranking"
  >
    <v-row>
      <v-col
        cols="12"
        class="text-xs-center"
      >
        <h2 class="menu-header white--text mt-0 mb-2">
          Crioscopia
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <date-range-picker
          v-model="filters.date.input"
          @change="onDateFilter"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <person-autocomplete-filter
          v-model="filters.tecnico"
          label="Escolher Técnico"
          type="TECHNICIAN"
          filled
          @change="onFilterChange"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-select
          v-model="filters.analysisType"
          :items="cryoscopyOptions"
          hide-details
          filled
          clearable
          label="Tipo"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-text-field
          v-model="filters.search"
          hide-details
          filled
          clearable
          label="Buscar"
          prepend-inner-icon="search"
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="general.tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tabs-slider color="white" />

      <v-tab href="#reports">
        Relatório
      </v-tab>

      <v-tab href="#graphs">
        Gráfico
      </v-tab>

      <v-tab-item value="reports">
        <v-card
          dark
          class="report-card"
          color="transparent"
        >
          <data-table
            ref="report"
            v-model="selectedAnalysis"
            name="Desconto Água Produtor"
            dark
            :headers="headers"
            :items="filteredItems"
            item-key="analysisId"
            show-custom-group
            :show-select="!isAutomaticDiscount && hasCrioscopyBillAccess"
          >
            <template #[`item.analysis.cryoscopy`]="{ value, item }">
              {{ formatNumber(value, 3) }}
              <v-tooltip
                v-if="item.analysis.cryoscopyList.length > 1"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>

                <div
                  v-for="(result, idx) in item.analysis.cryoscopyList"
                  :key="idx"
                  class="d-block"
                >
                  {{ formatNumber(result, 3) }}
                </div>
              </v-tooltip>
            </template>
            <template #[`item.analysis.waterPercentage`]="{ value, item }">
              {{ formatNumber(value) }}
              <v-tooltip
                v-if="item.analysis.percentageList.length > 1"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>

                <div
                  v-for="(result, idx) in item.analysis.percentageList"
                  :key="idx"
                  class="d-block"
                >
                  {{ formatNumber(result) }}
                </div>
              </v-tooltip>
            </template>
            <template #[`item.analysis.waterLiters`]="{ value, item }">
              {{ formatNumber(value) }}
              <v-tooltip
                v-if="item.analysis.waterList.length > 1"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>

                <div
                  v-for="(result, idx) in item.analysis.waterList"
                  :key="idx"
                  class="d-block"
                >
                  {{ formatNumber(result) }}
                </div>
              </v-tooltip>
            </template>

            <template #[`item.discountId`]="{ value, item }">
              <v-tooltip
                v-if="isAutomaticDiscount"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    style="position:relative"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      receipt
                    </v-icon>
                    <v-icon
                      small
                      class="financial-badge"
                      color="green accent-4"
                    >
                      check_circle
                    </v-icon>
                  </v-btn>
                </template>

                Desconto ativado na bonificação
              </v-tooltip>
              <v-tooltip
                v-else-if="value"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    :disabled="!hasCrioscopyBillAccess"
                    style="position:relative"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="showBill(item)"
                  >
                    <v-icon>
                      attach_money
                    </v-icon>
                    <v-icon
                      small
                      class="financial-badge"
                      color="green accent-4"
                    >
                      check_circle
                    </v-icon>
                  </v-btn>
                </template>

                Exibir conta
              </v-tooltip>
              <v-tooltip
                v-else
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    :disabled="!hasCrioscopyBillAccess"
                    style="position:relative"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="generateBill(item)"
                  >
                    <v-icon>
                      attach_money
                    </v-icon>
                    <v-icon
                      small
                      class="financial-badge"
                      color="red accent-3"
                    >
                      remove_circle
                    </v-icon>
                  </v-btn>
                </template>

                Gerar conta
              </v-tooltip>
            </template>
          </data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item value="graphs">
        <v-card
          class="graph-card"
          dark
          color="transparent"
        >
          <v-chart
            :options="graph.options"
            :style="{width: '100%', height: `${graph.height}px !important`}"
            autoresize
          />
        </v-card>
      </v-tab-item>
    </v-tabs>

    <discount-dialog
      v-model="discountDialog.show"
      :discount-id="discountDialog.id"
      @input="discountDialog.id = null"
      @removeDiscount="onFilterChange"
      @newDiscount="onFilterChange"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="selectedAnalysis.length"
        fab
        dark
        color="orange darken-4"
        @click="generateBills"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              attach_money
            </v-icon>
          </template>
          Gerar contas
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.cryoscopy-ranking {
  .graph-card {
    margin-top: 5px !important;
    background: rgba(0, 0, 0, 0.5) !important;

    > .v-card__title {
      color: #fff;
      background: unset;
    }

    > .v-card__text {
      color: #000 !important;
    }
  }

  .financial-badge {
    font-size: 16px !important;
    position: absolute !important;
    top: -10px;
    right: 0px;
  }
}
</style>

<script>
import moment from "moment";
import _ from "lodash";

import colors from 'vuetify/lib/util/colors'
import DiscountDialog from '@/Domains/Financial/MilkDiscounts/Components/DiscountDialog.vue';
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

const LEITE_CRU_REFRIGERADO = '8b794623-d6ff-40bd-b61d-49bbc666eb32';

export default {

  components: {
    DiscountDialog,
    DateRangePicker,
    PersonAutocompleteFilter
  },

  data() {
    return {
      loading: false,

      filters: {

        date: {
          input: "thisMonth",
          range: [],
        },

        search: "",

        tecnico: {},

        tecnicos: [],

        analysisType: null
      },
      persons: {},

      headers: [
        { text: 'Código', value: 'itinerary.code', align: 'center' },
        { text: 'Rota', value: 'route.description', align: 'center' },
        { text: 'Cod. Produtor', value: 'producer.code', align: 'center' },
        { text: 'Produtor', value: 'producer.name', align: 'start' },
        { text: 'Data', value: 'date', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Tanque', value: 'tank', align: 'center' },
        { text: 'Vale (L)', value: 'vale', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Crioscopia', value: 'analysis.cryoscopy', align: 'center', formatter: value => this.formatNumber(value, 3), mask: '#,##0.000' },
        { text: '% Água', value: 'analysis.waterPercentage', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0.00' },
        { text: 'Água (L)', value: 'analysis.waterLiters', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0.00' },
        { text: '', altText: 'Conta', value: 'discountId', align: 'end', sortable: false, drag: false },
      ],

      list: [],

      selectedAnalysis: [],

      general: {
        tab: null,
        producer: [],
      },

      // Exibe dialogo de financeiro
      discountDialog: {
        show: false,
        id: null,
      },

    };
  },
  computed: {
    height() {
      return Math.max(this.graph.height, 300)
    },

    measurementParam() {
      return (this.$store.state.settings.plataforma || {}).medicao_descarga_plataforma || 'balanca'
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasCrioscopyBillAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "crioscopy-bill-access" && o.tipo === "COMPONENTE");
    },

    isAutomaticDiscount() {
      return this.$store.state.settings.gerais.calcular_crioscopia_bonificacao || false
    },

    analysisParams() {
      return _.get(this.$store.state.settings, `plataforma.parametros_analise.materia_prima.${LEITE_CRU_REFRIGERADO}`, []);
    },

    cryoscopyParam() {
      const param = this.analysisParams.filter(item => item.parametro === 'crioscopia')
      const standard = _.get(param, '0.padrao', {});
      return {
        min: parseFloat(standard.min),
        max: parseFloat(standard.max),
      };
    },

    cryoscopyOptions() {
      const options = [{
        text: 'Dentro do Padrão',
        value: 'DENTRO_PADRAO'
      }];

      if (this.cryoscopyParam.min || this.cryoscopyParam.max) {
        options.push({
          text: `Fora do Padrão: Min: ${this.cryoscopyParam.min} - Max: ${this.cryoscopyParam.max}`,
          value: 'FORA_PADRAO'
        });
      }

      return options;
    },

    filteredItems() {
      const filterType = !!this.filters.analysisType && (!!this.cryoscopyParam.min || !!this.cryoscopyParam.max);

      if (!this.filters.search && !filterType) {
        return this.list;
      }

      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;

      return this.list.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        let hasType = !filterType;

        if (filterType) {
          const isStandard = item.analysis.cryoscopy >= this.cryoscopyParam.min && item.analysis.cryoscopy <= this.cryoscopyParam.max;
          hasType = this.filters.analysisType === 'FORA_PADRAO' ? !isStandard : isStandard;
        }

        return hasSearch && hasType;
      });
    },

    graph() {
      return {
        height: 70 + (this.itemsGrafico.length * 25),
        options: {
          title: {
            text: 'Ranking de Crioscopia',
            subtext: `Produtores: ${this.itemsGrafico.length}`,
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'item',
            axisPointer: {
              type: 'shadow'
            }
          },
          color: ['rgba(184, 28, 28)', 'rgba(184, 28, 28)'],
          grid: {
            left: '1%',
            right: '5%',
            top: '50px',
            bottom: '0%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            scale: true,
            min: 0,
            boundaryGap: false,
            splitLine: { show: false },
            axisLine: { show: true },
            axisTick: { show: false },
            axisLabel: {
              textStyle: { color: 'transparent' }
            },
          },
          yAxis: {
            axisPointer: {
              show: true,
              type: 'shadow',
              triggerTooltip: false,
            },
            type: 'category',
            inverse: true,
            data: this.itemsGrafico.map(o => [o.codigo_laticinio, o.nome].filter(o => o).join(' - ')),
            axisLabel: {
              fontSize: 10,
              textStyle: { color: '#ddd' }
            },
          },
          series: [
            {
              name: '% de àgua',
              type: 'bar',
              label: {
                show: true,
                position: 'right',
                color: '#fff',
                distance: -1,
                padding: [2.5, 10],
                borderRadius: [0, 30, 30, 0],
                textShadowColor: '#000',
                textShadowBlur: 3,
                backgroundColor: colors.red.darken3
              },
              itemStyle: {
                normal: { color: colors.red.darken3 }
              },
              data: this.itemsGrafico.map(item => ({
                value: item.porcentagem_agua,
                itemStyle: {
                  normal: { color: colors.red.darken3 },
                },
                tooltip: {
                  trigger: 'item',
                  axisPointer: {
                    type: 'shadow'
                  },
                  formatter: [
                    '<b>{b}</b>',
                    `% de água: <b>${item.porcentagem_agua} </b>`,
                    `Vol. de água: <b>${item.volume} </b>`,
                    `Vol. de coleta: <b>${item.quantidade_coleta} </b>`,
                  ].join('<br>'),
                  borderColor: 'transparent',
                  textStyle: {
                    fontSize: 12
                  }
                },
              })),
            }
          ],
        }
      }
    },

    itemsGrafico() {
      if (!this.filters.search) {
        return this.general.producer;
      }

      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;

      return this.general.producer.filter(item => {

        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return searchFilter;
      });
    },

  },

  async mounted() {
    await this.loadList();
    await this.loadReportGraph();
  },

  methods: {

    async loadList() {
      try {

        const { data } = await this.$axios.post(`/descargaColeta/listaDescontoAguaProdutor`, {
          data_inicio: _.head(this.filters.date.range) || moment().startOf("month").format("YYYY-MM-DD"),
          data_fim: _.last(this.filters.date.range) || moment().endOf("month").format("YYYY-MM-DD"),
          id_usuario: _.get(this.filters.tecnico, 'id'),
        });

        if (!_.isArray(data)) {
          throw data;
        }

        this.list = data.map(row => {
          return {
            analysisId: row.id_descarga_analise,
            unloadId: row.id_descarga_coletas,
            tank: `Tanque ${row.tanque}`,
            date: row.data_hora_descarga,
            tecnico: {
              id: row.id_tecnico,
              name: row.tecnico,
            },
            producer: {
              id: row.id_produtor,
              name: row.nome_produtor,
              code: row.codigo_laticinio,
            },
            itinerary: {
              code: row.cod_itinerario,
            },
            vale: parseInt(row.vale) || 0.0,
            analysis: {
              cryoscopy: parseFloat(row.crioscopia) || 0.0,
              waterPercentage: parseFloat(row.porcentagem_agua) || 0.0,
              waterLiters: parseFloat(row.volume_agua) || 0.0,
              cryoscopyList: row.lista_crioscopia,
              percentageList: row.lista_porcentagens,
              waterList: row.lista_volumes_agua,
            },
            analysts: row.analistas.map(analyst => {
              return {
                id: analyst.id_pessoa,
                name: analyst.nome_pessoa,
                signature: analyst.assinatura,
              };
            }),
            route: {
              id: row.id_rota,
              description: row.nome_rota,
            },
            discountId: row.id_desconto_produtor
          };
        });
      } catch (error) {
        console.warn(error);
      }
    },

    async loadReportGraph() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/platform/reports/cryoscopy-ranking`, { params: {
          start_date: _.head(this.filters.date.range) || moment().startOf("month").format("YYYY-MM-DD"),
          end_date: _.last(this.filters.date.range) || moment().endOf("month").format("YYYY-MM-DD"),
          user_id: _.get(this.filters.tecnico, 'id'),
        } });

        this.general.producer = data;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event;

      return this.onFilterChange();
    },

    onFilterChange() {
      return this.loadList() && this.loadReportGraph();
    },

    showBill(item) {
      this.discountDialog.id = item.discountId;
      this.discountDialog.show = true;
    },

    async generateBills() {
      const ids = this.selectedAnalysis.filter(item => !item.discountId).map(item => item.analysisId);
      this.selectedAnalysis = [];

      if (ids.length === 0) {
        this.$snotify.info("Nenhuma análise enviada");
        return;
      }

      if (!(await this.$root.$confirm(
        'Atenção',
        `Esta ação irá gerar contas de descontos para <b>${ ids.length } produtores</b><br>Clique em SIM para Confirmar.`,
        { color: 'orange' }
      ))) {
        return;
      }

      this.createBills(ids);
    },

    async generateBill(item) {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Esta ação irá gerar uma conta de desconto de volume de <b>${ item.analysis.waterLiters } Litros</b><br>Clique em SIM para Confirmar.`,
        { color: 'orange' }
      ))) {
        return;
      }

      this.createBills([item.analysisId]);
    },

    async createBills(ids) {
      this.$root.$progressBar.saving();
      try {
        const { data } = await this.$axios.post(
          `/descontosProdutor/gerarContaCrioscopia`,
          {
            ids,
          }
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Conta gerada com sucesso", "Sucesso");
        this.onFilterChange();
      } catch (e) {
        console.warn(e);
        this.$snotify.error("Erro ao gerar a conta", "Atenção");
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    print() {
      const [startDate, endDate] = this.filters.date.range;
      const title = `Desconto Água Produtor - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const [startDate, endDate] = this.filters.date.range;
      const filename = `Desconto Água - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.exportExcel(null, filename);
    },

    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
    formatNumber: (value, decimals = 0) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: decimals }).format(value),

  },

}
</script>
