<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Gerar {{ type === 'NFPE' ? 'NFP-e' : 'Contra Nota' }}</span>
        <v-spacer />
        <v-btn
          v-if="!started"
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="6">
            <div class="font-weight-bold">
              Produtores Selecionados
            </div>
            <div class="text-h6 text-truncate secondary--text">
              {{ bonusIds.length }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-timeline dense>
              <v-slide-x-reverse-transition
                group
                hide-on-leave
              >
                <v-timeline-item
                  key="info"
                  color="info"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    type="info"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <template #prepend>
                      <v-progress-circular
                        v-if="started"
                        indeterminate
                        color="info"
                      />
                    </template>
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          {{ started ? 'Gerando' : 'Gerar' }} {{ bonusIds.length }} notas
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>

                <v-timeline-item
                  v-if="pendings.length"
                  key="pendings"
                  color="orange"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    type="success"
                    border="left"
                    color="orange"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          {{ pendings.length }} notas pendentes
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>

                <v-timeline-item
                  v-if="rejecteds"
                  key="rejecteds"
                  color="error"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    type="error"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          {{ rejecteds }} notas rejeitadas
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
              </v-slide-x-reverse-transition>
            </v-timeline>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          :disabled="started"
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="pendings.length"
          outlined
          color="teal"
          :disabled="started"
          @click="authorize()"
        >
          Autorizar notas pendentes
        </v-btn>
        <v-btn
          outlined
          color="primary"
          :loading="started"
          @click="generate()"
        >
          Gerar notas
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

const { notify, progressBar } = useUtils()

// eslint-disable-next-line no-undef
const emit = defineEmits(['authorize', 'issued'])

const dialog = ref(false)

const type = ref(null)
const bonusIds = ref([])
const pendings = ref([])
const started = ref(false)
const issueds = ref(0)
const rejecteds = ref(0)

const show = (data) => {
  dialog.value = true

  type.value = data.type
  bonusIds.value = data.bonusIds || []

  if (data.invoiceIds) {
    loadPendings(data.invoiceIds)
  }
}

const loadPendings = async (ids) => {
  try {
    progressBar.loading();
    const { data } = await axios.get(`/fiscal/invoice/pendings/`, { params: { ids } })
    pendings.value = data
  } catch (e) {
    console.warn(e)
  } finally {
    progressBar.hide();
  }
}

const close = (showEmit = true) => {
  if (showEmit && issueds.value) {
    emit('issued')
  }
  bonusIds.value = []
  pendings.value = []
  issueds.value = 0
  rejecteds.value = 0
  dialog.value = false
}

const generate = async () => {
  try {
    started.value = true
    const ids = bonusIds.value

    const { data } = type.value === 'NFPE'
      ? await axios.post(`/fiscal/invoice/issue-nfpe`, { ids })
      : await axios.post(`/fiscal/invoice/issue-producer`, { ids })

    issueds.value = data.issueds || 0
    rejecteds.value = data.rejecteds || 0
    pendings.value = data.pendings || []

    if (pendings.value.length) {
      setTimeout(() => {
        authorize()
      }, 1000)
    }

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao salvar!', 'Atenção')
  } finally {
    started.value = false
  }
}

const authorize = () => {
  emit('authorize', pendings.value)
  close(false)
}

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>
