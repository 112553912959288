<template>
  <v-dialog
    v-model="visible"
    width="800"
    max-width="800"
  >
    <v-card>
      <v-card-title>Mesclar cadastro</v-card-title>

      <v-card-text>
        <v-row
          class="mt-0"
        >
          <v-col
            cols="6"
            class="text-center pt-0"
          >
            <div class="subtitle-1 font-weight-bold green--text d-block">
              CADASTRO QUE SERÁ SALVO
            </div>
            <span>Este serão os dados que ficarão salvos no cadastro</span>
          </v-col>
          <v-col
            cols="6"
            class="text-center pt-0"
          >
            <div class="subtitle-1 font-weight-bold orange--text d-block">
              CADASTRO PENDENTE
            </div>
            <span>Esse cadastro será <span class="red--text">removido</span></span>
          </v-col>
        </v-row>
        <v-form ref="form">
          <div
            v-for="(item, idx) of comparative"
            :key="idx"
            class="d-flex justify-space-between"
          >
            <template v-if="item.type === 'section'">
              <span class="text-h5 mt-6">
                {{ item.text }}
              </span>
            </template>

            <template v-else-if="item.type === 'subtitle'">
              <span class="text-overline mt-4">
                {{ item.text }}
              </span>
            </template>

            <template v-else>
              <div
                class="pt-0 flex-grow-1"
              >
                <v-text-field
                  v-model="item.currentValue"
                  :label="item.text"
                  color="green"
                  readonly
                  hide-details
                />
              </div>

              <v-btn
                icon
                class="align-self-center"
                @click="changeValue(item)"
              >
                <v-icon>
                  multiple_stop
                </v-icon>
              </v-btn>

              <div
                class="pt-0 flex-grow-1"
              >
                <v-text-field
                  v-model="item.pendingValue"
                  :label="item.text"
                  color="orange"
                  :error="item.currentValue != item.pendingValue"
                  readonly
                  hide-details
                />
              </div>
            </template>
          </div>
        </v-form>

        <v-row v-if="pendingAttachments.length">
          <v-col cols="12">
            <span class="text-h5 mt-6">
              Anexos
            </span>
          </v-col>

          <v-col
            v-for="attachment in pendingAttachments"
            :key="attachment.url"
            cols="12"
          >
            <v-card>
              <v-list-item class="py-4">
                <v-list-item-action>
                  <v-checkbox v-model="attachment.selected" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-icon
                      medium
                      class="mr-2"
                      color="blue-grey darken-4"
                    >
                      attach_file
                    </v-icon>
                    <span class="font-weight-medium">{{ attachment.tipo }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    medium
                    color="blue"
                    @click="openUrl(attachment.url || attachment.uri)"
                  >
                    open_in_new
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="marker">
          <v-col cols="12">
            <span class="text-h5 mt-6">
              Coordenadas
            </span>
          </v-col>

          <v-col cols="6">
            <route-map
              ref="map"
              :center="$store.state.settings.coordenadas"
              clickable
              :value="[]"
              :markers="[marker.current]"
            />
          </v-col>
          <v-col cols="6">
            <route-map
              ref="map"
              :center="$store.state.settings.coordenadas"
              clickable
              :value="[]"
              :markers="[marker.pending]"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          text
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-btn
          class="white--text"
          color="blue"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RouteMap from '@/Domains/Routes/Components/Maps/RouteMap.vue';

import isObject from 'lodash/isObject';
import startCase from 'lodash/startCase';

const fields = [
  { type: 'section', text: 'Dados Cadastrais' },

  { field: 'codigo_laticinio', text: 'Código Laticínio' },
  { field: 'nome', text: 'Nome' },
  { field: 'cnpj_cpf', text: 'CPF/CNPJ' },
  { field: 'celular', text: 'Celular' },
  { field: 'telefone', text: 'Telefone' },
  { field: 'email', text: 'E-mail' },
  { field: 'codigo_sda', text: 'Código SDA' },
  { field: 'cidasc', text: 'CIDASC/IMA' },
  { field: 'rg', text: 'N° de Identidade (RG)' },
  { field: 'orgao_expedidor', text: 'Órgão expedidor' },
  { field: 'uf_expedicao', text: 'UF Expedição' },
  { field: 'nirf', text: 'N° NIRF' },
  { field: 'nrp', text: 'Código NRP' },
  { field: 'inscricao_estadual', text: 'Inscrição estadual' },
  { field: 'data_inicio_coleta', text: 'Data início coleta' },
  { field: 'data_nascimento', text: 'Data de nascimento' },
  { field: 'sif', text: 'Sif' },
  { field: 'cad_pro', text: 'CAD_PRO' },
  { field: 'observacao_tecnica', text: 'Observações' },
  { field: 'carta_anuencia', text: 'Carta de anuência' },

  { type: 'section', text: 'Endereço' },

  { field: 'nome_propriedade', text: 'Nome Propriedade' },
  { field: 'end_cep', text: 'CEP' },
  { field: 'end_rua', text: 'Endereço' },
  { field: 'end_numero', text: 'Número' },
  { field: 'end_estado', text: 'Estado' },
  { field: 'end_cidade', text: 'Cidade' },
  { field: 'end_bairro', text: 'Bairro' },
  { field: 'end_latitude', text: 'Latitude' },
  { field: 'end_longitude', text: 'Longitude' },
  { type: 'select', id: 'id_rota', field: 'rota', text: 'Rota' },
  { type: 'select', id: 'id_regiao', field: 'regiao', text: 'Região' },

  { type: 'section', text: 'Dados Pagamentos' },

  { type: 'subtitle', text: 'Conta Principal' },
  { type: 'object', field: 'dados_forma_pagamento' },

  { type: 'subtitle', text: 'Conta Alternativa' },
  { type: 'array', field: 'contas_alternativas' },

  { type: 'subtitle', text: 'Tributação' },

  { field: 'tributacao', text: 'Tributação' },
  { field: 'desconta_senar', text: 'Desconta SENAR' },
  { field: 'tributacao', text: 'Tributação' },
  { field: 'debita_inss', text: 'Debita INSS' },

  { type: 'section', text: 'Dados de Produção' },

  { field: 'numero_ordenha', text: 'Número de Ordenhas' },
  { field: 'capacidade_resf_inte', text: 'Capacidade dos resfriadores (L)' },
  { field: 'litros', text: 'Volume litros (Média dia)' },
  { field: 'numero_tarro', text: 'Número do tarro' },
  { field: 'quantidade_tanques', text: 'Quantidade de resfriadores' },
  { field: 'cbt', text: 'CPP' },
  { field: 'ccs', text: 'CCS' },
  { field: 'gordura', text: 'Gordura' },
  { field: 'proteina', text: 'Proteína' },
  { field: 'frequencia_coleta', text: 'Frequência de Coleta' },
  { field: 'tipo_resfriador', text: 'Tipo de resfriamento' },
  { field: 'tipo_ordenha', text: 'Tipo de ordenha' },
  { field: 'hora_inicio_coleta', text: 'Hora ínicio coleta' },
  { field: 'hora_fim_coleta', text: 'Hora fim coleta' },

  { type: 'section', text: 'Informações Adicionais' },

  { type: 'object', field: 'dados_dependentes', text: 'Dados Dependentes' },
];

export default {

  components: {
    RouteMap,
  },

  data() {
    return {
      visible: false,

      currentId: null,
      pendingId: null,

      comparative: [],

      currentAttachments: [],
      pendingAttachments: [],
    }
  },

  computed: {
    marker() {
      const lat = this.comparative.find(item => item.field === 'end_latitude');
      const lng = this.comparative.find(item => item.field === 'end_longitude');

      if (!lat || !lng || !lat.currentValue || !lat.pendingValue || !lng.currentValue || !lng.pendingValue) {
        return null
      }

      return {
        current: {
          location: {
            lat: lat.currentValue,
            lng: lng.currentValue,
          },
          visible: true,
          color: '#00838F',
          draggable: true,
          icon: 'person_outline',
        },
        pending: {
          location: {
            lat: lat.pendingValue,
            lng: lng.pendingValue,
          },
          visible: true,
          color: '#00838F',
          draggable: true,
          icon: 'person_outline',
        }
      };
    },
  },

  methods: {

    async show({ id }) {
      try {
        this.visible = true;
        this.$root.$progressBar.loading();

        const { data: pending } = await this.$axios.post(`/produtores/produtorDetalhe`, { id_pessoa: id });

        this.pendingId = id;
        this.currentId = pending.id_produtor;

        if (!this.currentId) {
          const { data: { dados } } = await this.$axios.post(`/produtores/verificarDocumento`, { id_produtor: id, cnpj_cpf: pending.cnpj_cpf });

          if (!dados) {
            throw new Error('Produtor não encontrado');
          }

          this.currentId = dados.id_produtor;
        }

        const { data: current } = await this.$axios.post(`/produtores/produtorDetalhe`, { id_pessoa: this.currentId });

        this.currentAttachments = current.anexos;
        this.pendingAttachments = pending.anexos;

        this.comparative = fields.reduce((acc, item) => {
          if (['section', 'subtitle'].includes(item.type)) {
            acc.push(item);
            return acc;
          }

          if (item.type === 'object') {
            const currentObject = current[item.field] || {};
            const pendingObject = pending[item.field] || {};

            Object.keys(pendingObject)
              .forEach((field) => {
                acc.push({
                  type: 'object',
                  object: item.field,
                  field: field,
                  text: startCase(field),
                  currentValue: currentObject[field],
                  pendingValue: pendingObject[field],
                });
              });

            return acc;
          }

          if (item.type === 'array') {
            const currentArray = current[item.field] || [];
            const pendingArray = pending[item.field] || [];

            for (let idx in pendingArray) {
              const currentObject = currentArray[idx] || {};
              const pendingObject = pendingArray[idx] || {};

              Object.keys(pendingObject)
                .forEach((field) => {
                  acc.push({
                    idx,
                    type: 'array',
                    array: item.field,
                    field: field,
                    text: startCase(field),
                    currentValue: currentObject[field],
                    pendingValue: pendingObject[field],
                  });
                });
            }

            return acc;
          }

          if (item.type === 'select') {
            acc.push({
              type: 'select',
              text: item.text,

              field: item.field,
              currentValue: current[item.field],
              pendingValue: pending[item.field],

              id: item.id,
              currentId: current[item.id],
              pendingId: pending[item.id],
            });

            return acc;
          }

          acc.push({
            field: item.field,
            text: item.text,
            currentValue: current[item.field],
            pendingValue: pending[item.field],
          });

          return acc;
        }, [])

      } catch (error) {
        this.$snotify.error('Erro ao carregar informações do produtor', 'Atenção');
        console.log(error);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const current = this.comparative
          .filter(item => !['section', 'subtitle'].includes(item.type))
          .reduce((acc, cur) => {
            if (cur.type === 'object') {
              if (!(cur.object in acc)) {
                acc[cur.object] = {};
              }
              acc[cur.object][cur.field] = cur.currentValue;
            }
            else if (cur.type === 'array') {
              if (!(cur.array in acc)) {
                acc[cur.array] = [];
              }
              if (!(cur.idx in acc[cur.array])) {
                acc[cur.array][cur.idx] = {};
              }
              acc[cur.array][cur.idx][cur.field] = cur.currentValue;
            }
            else {
              if (cur.type === 'select') {
                acc[cur.id] = cur.currentId;
              }
              acc[cur.field] = cur.currentValue;
            }
            return acc;
          }, {});

        current.anexos = [
          ...this.currentAttachments,
          ...this.pendingAttachments
            .filter(item => item.selected)
            .map(item => {
              delete item.selected;
              return item;
            })
        ];

        await Promise.all([
          this.updateProducer(this.currentId, current),
          this.changeReferences(this.pendingId, this.currentId),
          this.deleteProducer(this.pendingId),
        ]);

        this.$emit('save');
        this.close();
        this.$snotify.success('Cadastro salvo com sucesso!', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async updateProducer(id, payload) {
      await this.$axios.put(`/producer/${id}`, payload);
    },

    async changeReferences(originId, destinationId) {
      await this.$axios.post(`/producer/change-references`, {
        id_produtor_origem: originId,
        id_produtor_destino: destinationId,
      });
    },

    async deleteProducer(id) {
      const { data } = await this.$axios.post(`/produtores/deleteProdutor`, {
        id_produtor: id,
      });

      if (!isObject(data)) {
        throw 'PHP Error';
      }

      if (!data.codigo) {
        throw 'Erro ao deletar produtor';
      }
    },

    changeValue(item) {
      const aux = item.currentValue;
      item.currentValue = item.pendingValue;
      item.pendingValue = aux;
      if (item.type === 'select') {
        const aux = item.currentId;
        item.currentId = item.pendingId;
        item.pendingId = aux;
      }
    },

    close() {
      this.form = {};
      this.$refs.form && this.$refs.form.resetValidation();
      this.visible = false;
    },

    openUrl(url) {
      window.open(url);
    },
  }
}
</script>
