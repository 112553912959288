<template>
  <div class="mx-6 px-sm-16">
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Fechamento de Conta de Produtor
        </h2>
        <bonus-product-name :raw-material-id="rawMaterialId" />
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        class="pt-0"
      >
        <CardInfo
          title="Cotação Média"
          icon="icon-bonificacao"
          :value="formatCurrency(averageBaseValue)"
          :loading="loading"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        class="pt-0"
      >
        <CardInfo
          title="Volume Total"
          icon="icon-coleta_principal"
          :value="`${ formatNumber(totalVol) } L`"
          :loading="loading"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        class="pt-0"
      >
        <CardInfo
          title="Preço Médio / Litro"
          icon="icon-tipo_operacao"
          :value="formatCurrency(milkPriceAvg)"
          :loading="loading"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        class="pt-0"
      >
        <CardInfo
          title="Vales e Descontos"
          icon="icon-contas_pagar"
          :value="formatCurrency(totalVouchersAndDiscounts)"
          :loading="loading"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        class="pt-0"
      >
        <CardInfo
          title="Taxas"
          icon="icon-contas_receber"
          :value="formatCurrency(totalFees)"
          :loading="loading"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        class="pt-0"
      >
        <CardInfo
          title="Total à Pagar"
          icon="icon-quitacao_contas"
          :value="formatCurrency(totalPaidAmount)"
          :loading="loading"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <v-card
          dark
          class="bonus-list"
          color="transparent"
        >
          <v-card-title class="pb-0">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg
                class="pb-0"
              >
                <v-menu
                  ref="menu"
                  transition="scale-transition"
                  offset-y
                  content-class="menu-select"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="dateRefFilter"
                      label="Mês de Referência"
                      prepend-inner-icon="event"
                      readonly
                      filled
                      hide-details
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="filters.dateFilter"
                    :max="maxDateRef"
                    type="month"
                    no-title
                    scrollable
                    @change="onDateFilterChange"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg
                class="pb-0"
              >
                <v-autocomplete
                  v-model="filters.route"
                  label="Escolher Rota"
                  :items="filters.routes"
                  prepend-inner-icon="icon-rota"
                  item-value="id_rota"
                  item-text="descricao"
                  filled
                  multiple
                  clearable
                  @change="onFilterChange"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg
                class="pb-0"
              >
                <v-autocomplete
                  v-model="filters.region"
                  label="Escolher Região"
                  :items="filters.regions"
                  prepend-inner-icon="map"
                  item-value="id_regiao"
                  item-text="descricao"
                  filled
                  multiple
                  clearable
                  @change="onFilterChange"
                />
              </v-col>
              <v-col
                v-if="hasInvoiceAccess"
                cols="12"
                sm="6"
                md="4"
                lg
                class="pb-0"
              >
                <v-select
                  v-model="filters.invoiceStatus"
                  :items="[
                    'PENDENTE',
                    'REJEITADA',
                    'EMITIDA',
                    'CANCELADA',
                    'DENEGADA',
                  ]"
                  clearable
                  filled
                  hide-details
                  label="Status Nota Fiscal"
                  prepend-inner-icon="receipt"
                  multiple
                  small-chips
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg
                class="pb-0"
              >
                <masked-text-field
                  v-model="filters.complementary.qty"
                  label="Qtde. de Complementos"
                  prepend-inner-icon="post_add"
                  :prefix="filters.complementary.operator"
                  :mask="{ mask: Number, scale: 0, min: 0, max: 100 }"
                  unmask
                  filled
                  hide-details
                >
                  <template #[`prepend-inner`]>
                    <v-menu
                      bottom
                      fixed
                    >
                      <template #activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          rotate_right
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, idx) of filters.complementary.items"
                          :key="idx"
                          class="px-2"
                          @click="filters.complementary.operator = item.value"
                        >
                          <b class="mr-3">{{ item.value }}</b>
                          <span class="overline">{{ item.text }}</span>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </masked-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg
                class="pb-0"
              >
                <v-text-field
                  v-model="filters.search"
                  label="Pesquisar"
                  prepend-inner-icon="search"
                  filled
                  hide-details
                  clearable
                />
              </v-col>
            </v-row>
          </v-card-title>

          <data-table
            ref="report"
            v-model="selectedProducers"
            name="Fechamento de Conta de Produtor"
            :headers.sync="headers"
            :items="filteredBonusList"
            :expanded.sync="selectedRow"
            :sort-by.sync="filters.sort.by"
            :sort-desc.sync="filters.sort.desc"
            item-key="id"
            class="elevation-1 bonus-list-table"
            show-expand
            single-expand
            show-select
            :item-class="(item) => item.finalPriceEdited ? 'row-editing' : ''"
            :footer-props="{
              'items-per-page-options': [50, 100, 200, -1]
            }"
            :items-per-page="15"
          >
            <template #[`item.producerInvoice`]="{ value }">
              <div
                :class="{ 'link': !!value.id }"
                @click.stop="value.id ? showInvoice('NFPE', value.id) : null"
              >
                <v-tooltip
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      color="blue darken-4"
                      x-small
                      v-on="on"
                    >
                      {{ value.number || '-' }}
                    </v-chip>
                  </template>
                  Número
                </v-tooltip>
                <br>
                <v-tooltip
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      color="blue darken-4"
                      x-small
                      v-on="on"
                    >
                      {{ value.series || '-' }}
                    </v-chip>
                  </template>
                  Série
                </v-tooltip>
                <br>
                <v-tooltip
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      color="blue darken-4"
                      x-small
                      v-on="on"
                    >
                      {{ formatDate(value.emissionDate, 'DD/MM/YYYY') }}
                    </v-chip>
                  </template>
                  Data de emissão
                </v-tooltip>
              </div>
            </template>

            <template #[`item.ficha_leite`]="{ item }">
              <div class="d-flex align-center justify-center">
                <template>
                  <v-text-field
                    v-model="item.ficha_leite"
                    autocomplete="off"
                  >
                    <template #append>
                      <v-tooltip
                        top
                      >
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            small
                            v-on="on"
                            @click="replicarValor"
                          >
                            <v-icon small>
                              keyboard_double_arrow_down
                            </v-icon>
                          </v-btn>
                        </template>
                        Replicar N°
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </template>
              </div>
            </template>

            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col>
                    <v-card
                      color="transparent"
                      class="table-detail-container"
                      dark
                    >
                      <v-card-title
                        class="justify-center text-body-2"
                        style="background: rgba(0,0,0,.5);"
                      >
                        <span>Produtor</span>
                      </v-card-title>

                      <v-card-text class="pa-0 text-left">
                        <v-list color="rgba(0,0,0,.3)">
                          <v-list-item
                            dark
                            dense
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.producer.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle>
                                # {{ item.producer.code }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      color="transparent"
                      class="table-detail-container"
                      dark
                    >
                      <v-card-title
                        class="justify-center text-body-2"
                        style="background: rgba(0,0,0,.5);"
                      >
                        <span>Resultados de Qualidade</span>
                      </v-card-title>

                      <v-card-text class="pa-0 text-left">
                        <v-list color="rgba(0,0,0,.3)">
                          <template v-for="(result, index) in item.result.quality">
                            <v-list-item
                              v-if="result.amount"
                              :key="index"
                              dark
                              dense
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ result.label }}: {{ result.result }}
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                  {{ applyMask(result.amount, CurrencyMask) }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      color="transparent"
                      class="table-detail-container"
                      dark
                    >
                      <v-card-title
                        class="justify-center text-body-2"
                        style="background: rgba(0,0,0,.5);"
                      >
                        <span>Adicionais</span>
                      </v-card-title>

                      <v-card-text class="pa-0 text-left">
                        <v-list color="rgba(0,0,0,.3)">
                          <template v-for="(result, index) in item.result.others">
                            <v-list-item
                              v-if="result.amount"
                              :key="index"
                              dark
                              dense
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ result.label }}<span v-if="result.result">: {{ result.result }}</span>
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                  {{ applyMask(result.amount, CurrencyMask) }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      color="transparent"
                      class="table-detail-container"
                      dark
                    >
                      <v-card-title
                        class="justify-center text-body-2"
                        style="background: rgba(0,0,0,.5);"
                      >
                        <span>Vales / Descontos</span>
                      </v-card-title>

                      <v-card-text class="pa-0 text-left">
                        <v-list color="rgba(0,0,0,.3)">
                          <template v-for="(result, index) in item.valesAndDiscounts">
                            <v-list-item
                              v-if="result.amount"
                              :key="index"
                              dark
                              dense
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ result.type }}: {{ result.description }}
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                  {{ applyMask(result.amount, CurrencyMask) }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      color="transparent"
                      class="table-detail-container"
                      dark
                    >
                      <v-card-title
                        class="justify-center text-body-2"
                        style="background: rgba(0,0,0,.5);"
                      >
                        <span>Taxas</span>
                      </v-card-title>

                      <v-card-text class="pa-0 text-left">
                        <v-list color="rgba(0,0,0,.3)">
                          <template v-for="(result, index) in item.result.taxes">
                            <v-list-item
                              v-if="result.amount"
                              :key="index"
                              dark
                              dense
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ result.label }}:
                                </v-list-item-title>

                                <v-list-item-subtitle>
                                  {{ applyMask(result.amount, CurrencyMask) }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>

            <template #[`item.vol`]="{ item }">
              {{ applyMask(item.vol.toString(), NumberMask) }} L
            </template>

            <template #[`item.result.others.brucelose.result`]="{ value }">
              {{ !value ? 'Vencido' : 'Não Vencido' }}
            </template>

            <template #[`item.result.others.tuberculose.result`]="{ value }">
              {{ !value ? 'Vencido' : 'Não Vencido' }}
            </template>

            <template #[`item.result.totals.quality`]="{ item }">
              <template v-if="!item.finalPriceEdited">
                {{ applyMask(item.result.totals.quality, CurrencyMask) }}
              </template>
            </template>

            <template #[`item.result.totals.others`]="{ item }">
              <template v-if="!item.finalPriceEdited">
                {{ applyMask(item.result.totals.others, CurrencyMask) }}
              </template>
            </template>

            <template #[`item.baseAmount`]="{ item }">
              {{ applyMask(getRowBaseAmount(item), CurrencyMask) }}
            </template>

            <template #[`item.result.additionals.dairy`]="{ item }">
              <template v-if="!item.finalPriceEdited">
                {{ applyMask(item.result.additionals.dairy, CurrencyMask) }}
              </template>
            </template>

            <template #[`item.result.additionals.technical`]="{ item }">
              <template v-if="!item.finalPriceEdited">
                {{ applyMask(item.result.additionals.technical, CurrencyMask) }}
              </template>
            </template>

            <template #[`item.result.additionals.complementary`]="{ item }">
              <template v-if="!item.finalPriceEdited">
                {{ applyMask(item.result.additionals.complementary, CurrencyMask) }}
              </template>
            </template>

            <template #[`item.finalPrice`]="{ item }">
              <v-row align="center">
                <v-col
                  class="pa-0"
                >
                  <masked-text-field
                    v-if="item.editFinalPrice"
                    :ref="`finalPrice-${item.index}`"
                    v-model="item.finalPriceEdited"
                    class="text-body-2 pt-0 mb-2 ma-0"
                    prefix="R$"
                    :mask="MilkCurrencyMask"
                    unmask
                    hide-details
                    :disabled="item.locked && !isAdmin"
                  />
                  <span
                    v-else
                    class="mr-2"
                  >
                    {{ applyMask(getRowFinalPrice(item), CurrencyMask) }}
                  </span>
                </v-col>
                <v-btn
                  v-if="!item.invoiceLocked"
                  icon
                  small
                  @click.stop="item.editFinalPrice = !item.editFinalPrice"
                >
                  <v-icon small>
                    edit
                  </v-icon>
                </v-btn>
              </v-row>
            </template>

            <template #[`item.totalValue`]="{ item }">
              {{ applyMask(getRowTotalPrice(item), CurrencyMask) }}
            </template>

            <template #[`item.financials.vales`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.discounts`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.agreements`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.antibiotics`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.normative`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.cryoscopy`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.totals.valesAndDiscounts`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.taxes.funrural.amount`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.taxes.fundesa.amount`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.taxes.senar.amount`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.taxes.rat.amount`]="{ value }">
              {{ applyMask(value, NumberMask) }}
            </template>

            <template #[`item.financials.taxes.admin.amount`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.financials.totals.taxes`]="{ value }">
              {{ applyMask(value, CurrencyMask) }}
            </template>

            <template #[`item.finalValue`]="{ item }">
              <template v-if="!item.finalPriceEdited">
                {{ applyMask(item.finalValue, CurrencyMask) }}
              </template>
            </template>

            <template #[`item.accountId`]="{ value }">
              <v-btn
                v-if="value"
                icon
                @click.stop="showBill(value)"
              >
                <v-icon>
                  attach_money
                </v-icon>
              </v-btn>
            </template>

            <template #[`item.bankAccount`]="{ value }">
              {{ value['Forma Pagamento'] }}
            </template>

            <template #[`item.existsXml`]="{ item }">
              <div
                class="text-center"
              >
                <v-icon
                  :color="item.existsXml ? 'green' : 'gray'"
                >
                  newspaper
                </v-icon>
              </div>
            </template>

            <template #[`item.invoice.number`]="{ item }">
              <div class="d-flex align-center justify-center">
                <v-text-field
                  :ref="`invoiceNumber-${item.index}`"
                  v-model.number="item.invoice.number"
                  type="number"
                  dense
                  hide-details
                  placeholder=" "
                  class="text-body-2"
                  :disabled="!hasInvoiceNumberAccess || item.invoiceLocked || !isLocked"
                >
                  <template #append>
                    <v-tooltip
                      v-if="!item.invoiceLocked && isLocked"
                      top
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          @click="applyInvoiceSequence(item)"
                        >
                          <v-icon small>
                            keyboard_double_arrow_down
                          </v-icon>
                        </v-btn>
                      </template>

                      Aplicar sequência
                    </v-tooltip>
                  </template>
                </v-text-field>

                <v-tooltip
                  v-if="!!item.invoice.status && item.invoice.type === 'PROPRIA'"
                  top
                >
                  <template #activator="{ on }">
                    <v-avatar
                      :color="getInvoiceColor(item.invoice.status)"
                      size="16"
                      :class="{ 'link': !!item.invoice.id }"
                      v-on="on"
                      @click.stop="item.invoice.id ? showInvoice('ENTRADA', item.invoice.id) : null"
                    >
                      <span class="white--text text-overline">{{ item.invoice.status[0] }}</span>
                    </v-avatar>
                  </template>

                  {{ item.invoice.status }}
                  <span
                    v-if="item.invoice.emissionDate"
                    class="d-block"
                  >
                    Emissão: {{ formatDate(item.invoice.emissionDate, 'DD/MM/YYYY HH:mm') }}
                  </span>
                </v-tooltip>
              </div>
            </template>

            <template #[`item.invoiceNumberComplementary`]="{ item }">
              <v-menu
                v-model="item.invoiceNumberComplementary.show"
                :close-on-content-click="false"
                :nudge-width="150"
              >
                <template #activator="{ on }">
                  <div class="d-flex align-center justify-end">
                    <span>
                      {{ getComplementaryInvoicesNumber(item.invoiceNumberComplementary) }}
                    </span>
                    <template v-if="item.invoiceNumberComplementary.items.length > 0">
                      <v-tooltip
                        v-if="item.financialLocked || !isLocked"
                        top
                      >
                        <template #activator="{ on }">
                          <v-icon
                            small
                            class="ml-3"
                            v-on="on"
                          >
                            lock
                          </v-icon>
                        </template>

                        Este registro está bloqueado para edição!
                      </v-tooltip>
                      <v-tooltip
                        v-else-if="isAdmin"
                        top
                      >
                        <template #activator="{ on }">
                          <v-icon
                            small
                            class="ml-3"
                            v-on="on"
                            @click="item.invoiceNumberComplementary.show = true"
                          >
                            edit
                          </v-icon>
                        </template>

                        Adicionar nota fiscal complementar
                      </v-tooltip>
                    </template>
                  </div>
                </template>

                <producer-complementary-menu
                  :items.sync="item.invoiceNumberComplementary.items"
                  :show.sync="item.invoiceNumberComplementary.show"
                  edit-invoice
                />
              </v-menu>
            </template>
          </data-table>
        </v-card>

        <v-dialog
          v-model="confirmBillDialog.show"
          :max-width="380"
        >
          <v-card>
            <v-card-title
              :class="`blue--text text-h5`"
            >
              Gerar financeiro
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <date-picker
                    v-model="confirmBillDialog.startDate"
                    label="Data inicial de contas"
                    prepend-inner-icon="event"
                    hide-details
                  />
                </v-col>
                <v-col cols="6">
                  <date-picker
                    v-model="confirmBillDialog.endDate"
                    label="Data final de contas"
                    prepend-inner-icon="event"
                    hide-details
                  />
                </v-col>
                <v-col cols="12">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="caption">
                        Quitar contas
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-switch
                          v-model="confirmBillDialog.payOffBills"
                          :label="confirmBillDialog.payOffBills ? 'Ativo' : 'Inativo'"
                          hide-details
                          class="mt-0"
                        />
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="grey"
                outlined
                @click.native="confirmBillDialog.show = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="blue"
                outlined
                @click.native="generateFinancial"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <bill-dialog
          v-model="billDialog.show"
          :bill-id="billDialog.id"
          :type="billDialog.type"
          @input="billDialog.id = null"
          @removeBill="loadList"
        />

        <print-settings-dialog
          ref="print-settings"
          @print="print"
        />

        <v-speed-dial
          fixed
          dark
          bottom
          right
          open-on-hover
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-5"
        >
          <template #activator>
            <v-btn
              color="blue darken-2"
              dark
              large
              fab
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>

          <v-btn
            v-if="hasRowsToSave"
            fab
            dark
            color="blue darken-4"
            @click="save"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  save
                </v-icon>
              </template>

              Salvar
            </v-tooltip>
          </v-btn>

          <v-btn
            color="deep-purple lighten-1"
            fab
            dark
            :style="!isDairySelected ? getDisabledStyle() : {}"
            @click="isDairySelected ? showGenerateFinancialDialog() : null"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  attach_money
                </v-icon>
              </template>

              <span v-if="!isDairySelected">
                Laticínio não selecionado
              </span>
              <span v-else>
                Gerar financeiro
              </span>
            </v-tooltip>
          </v-btn>

          <v-btn
            fab
            dark
            :style="(!isLocked && !isFinancialLocked) ? getDisabledStyle() : {}"
            @click="(isLocked || isFinancialLocked) ? changeStatus() : null"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                >
                  {{ !isFinancialLocked ? 'done_all' : 'remove_done' }}
                </v-icon>
              </template>

              <span v-if="!isLocked && !isFinancialLocked">
                Só é possível concluir após o bloqueio da bonificação
              </span>
              <span v-else>
                {{ !isFinancialLocked ? 'Concluir para Gerar NF' : 'Reabrir para Edição' }}
              </span>
            </v-tooltip>
          </v-btn>

          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  backup_table
                </v-icon>
              </template>
              Download (Excel)
            </v-tooltip>
          </v-btn>

          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="printReport()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  print
                </v-icon>
              </template>

              Imprimir
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="selectedProducers.length"
            fab
            dark
            color="orange darken-1"
            @click="showPrintDialog"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  receipt_long
                </v-icon>
              </template>
              Imprimir Recibo
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="producers.length"
            fab
            dark
            color="orange darken-3"
            @click="printByRoute"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  print
                </v-icon>
              </template>
              Imprimir Extrato Por Rota
            </v-tooltip>
          </v-btn>

          <v-btn
            fab
            dark
            color="grey darken-1"
            @click="download()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  text_snippet
                </v-icon>
              </template>

              Download TXT
            </v-tooltip>
          </v-btn>

          <v-btn
            fab
            dark
            color="blue-grey darken-4"
            :style="!isDairySelected ? getDisabledStyle() : {}"
            @click="isDairySelected ? showNotes = true : null"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  attach_money
                </v-icon>
              </template>

              <span v-if="!isDairySelected">
                Laticínio não selecionado
              </span>
              <span v-else>
                Lançar Notas
              </span>
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="hasInvoiceAccess && selectedProducers.length"
            fab
            dark
            color="blue lighten-1"
            @click="issueFiscalInvoice"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  request_page
                </v-icon>
              </template>
              Gerar Notas Fiscais
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="hasInvoiceAccess && selectedProducers.length"
            fab
            dark
            color="teal"
            @click="issueNFPe"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  contact_page
                </v-icon>
              </template>
              Gerar NFP-e
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="hasInvoiceDeleteAccess && selectedProducers.length"
            fab
            dark
            color="red lighten-2"
            @click="clearInvoiceNumber"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  clear
                </v-icon>
              </template>
              Apagar número de notas fiscais
            </v-tooltip>
          </v-btn>

          <v-btn
            fab
            dark
            color="blue-grey darken-4"
            @click="() => showConfig = true"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  settings
                </v-icon>
              </template>

              Configurações
            </v-tooltip>
          </v-btn>
        </v-speed-dial>

        <v-overlay :value="loading">
          <v-card-text>
            Carregando...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-overlay>
      </v-col>
    </v-row>

    <producer-statement-settings v-model="showConfig" />
    <producer-notes
      v-model="showNotes"
      :producers="producers"
      @update="updateTable"
    />

    <invoice-dialog
      v-model="invoiceDialog.show"
      :type="invoiceDialog.type"
      :edit-id="invoiceDialog.id"
      @close="loadList()"
    />

    <invoice-processing-dialog
      ref="invoiceProcessingDialog"
      @issued="loadList()"
      @authorize="onAuthorize"
    />

    <invoice-batch-auth-dialog
      ref="batchAuthDialog"
      @close="loadList()"
    />
  </div>
</template>

<style lang="scss">
.row-editing {
  background: rgba(255, 0, 0, 0.25);
}

.rota-lista > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.lista-rotas-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<script>
import _, { isNull } from "lodash";
import moment from "moment";
import IMask from "imask";
import printJS from "print-js";
import { sortItems } from 'vuetify/lib/util/helpers'

import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import DatePicker from "@/Domains/Visits/Schedule/Components/DatePicker.vue";
import CardInfo from "@/Domains/BI/Components/CardInfo.vue";
import BonusProductName from "@/Domains/Producer/Bonus/BonusSpreadsheet/Components/BonusProductName.vue";
import ProducerComplementaryMenu from "@/Domains/Producer/Bonus/BonusSpreadsheet/Components/ProducerComplementaryMenu.vue";
import ProducerStatementSettings from '@/Domains/Financial/Views/Components/ProducerStatementSettings.vue';
import ProducerNotes from '@/Domains/Financial/Views/Components/ProducerNotes.vue';
import InvoiceProcessingDialog from '@/Domains/Financial/Views/Components/InvoiceProcessingDialog.vue';
import InvoiceBatchAuthDialog from '@/Domains/Fiscal/Components/InvoiceBatchAuthDialog.vue';
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import PrintSettingsDialog from '@/Support/Components/PrintSettingsDialog.vue';
import InvoiceDialog from '@/Domains/Fiscal/Components/InvoiceDialog.vue';
import isEmpty from 'lodash/isEmpty';

const isInteger = (value) => value.match(/^-?\d+$/);

const AccountTypesEnum = {
  CONTA_CORRENTE: 'Corrente',
  CONTA_POUPANCA: 'Poupança',
  CONTA_SALARIO: 'Salário',
};

export default {

  components: {
    BillDialog,
    DatePicker,
    CardInfo,
    BonusProductName,
    ProducerComplementaryMenu,
    ProducerStatementSettings,
    ProducerNotes,
    InvoiceProcessingDialog,
    InvoiceBatchAuthDialog,
    MaskedTextField,
    PrintSettingsDialog,
    InvoiceDialog,
  },

  props: {
    rawMaterialType: {
      type: String,
      default: 'PRINCIPAL',
    }
  },

  data() {
    return {
      rawMaterialId: null,

      // Loaders
      loading: false,

      showConfig: false,
      showNotes: false,

      // Filtros usados para buscar a rota
      filters: {
        sort: { by: 'producer.name', desc: false },

        dateFilter: moment().subtract(1, "month").format("YYYY-MM"),

        // Dialogs
        datePickerMenu: null,

        // Mês de referência para a bonificação
        date: null,

        // Rota filtrada para busca
        route: [],

        // Lista de rotas disponíveis no formulário
        routes: [],

        // Campo de busca para buscar pelo nome do produtor
        search: '',

        // Região filtrada para busca
        region: [],

        // Lista de regiões disponíveis no formulário
        regions: [],

        invoiceStatus: [],

        complementary: {
          items: [
            { text: 'Maior ou igual', value: '>=' },
            { text: 'Menor ou igual', value: '<=' },
            { text: 'Igual', value: '==' },
          ],
          operator: '>=',
          qty: '',
        },
      },

      // Lista de produtores para geração da bonificação
      producers: [],

      selectedProducers: [],

      // Exibe dialogo de financeiro
      confirmBillDialog: {
        show: false,
        startDate: null,
        endDate: null,
        payOffBills: true,
      },

      // Exibe dialogo de financeiro
      billDialog: {
        show: false,
        type: 'VALE',
        id: null,
      },

      // Lista de headers de qualidade para serem exibidas na tabela
      additionalHeaders: {},

      // Rota que está sendo exibida
      selectedRow: [],

      invoiceDialog: {
        show: false,
        type: null,
        id: null,
      },

      // Mascara usada nos valores adicionáis do leite
      MilkCurrencyMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            signed: true,
          }
        }
      },

      // Mascara usada nos valores em R$
      CurrencyMask: {
        mask: 'R$ num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: '.',
            scale: 4,
            signed: true,
          }
        }
      },

      // Mascara usada nos damais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: '.',
            signed: true,
          }
        }
      },
    };
  },

  computed: {

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format("YYYY-MM");
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (_.isEmpty(this.filters.dateFilter)) {
        return null;
      }

      return moment(this.filters.dateFilter, "YYYY-MM").format("MM/YYYY");
    },

    /**
     * Lista filtrada de produtores
     * @returns {Array}
     */
    filteredBonusList() {
      if (_.isEmpty(this.filters.search) && _.isEmpty(this.filters.invoiceStatus) && !isInteger(this.filters.complementary.qty)) {
        return this.producers;
      }

      const search = String(this.filters.search).toUpperCase().trim();
      const invoiceStatus = this.filters.invoiceStatus || [];
      const complementaryOperator = this.filters.complementary.operator;
      const complementaryQty = this.filters.complementary.qty;

      return this.producers.filter(({ producer, invoice, invoiceNumberComplementary }) => {
        const hasSearch = !search || `${producer.code || ''} - ${producer.name.toUpperCase()}`.includes(search);
        const hasInvoiceStatus = invoiceStatus.length === 0 || invoiceStatus.includes(invoice.status);
        const hasComplementaryQty = !isInteger(complementaryQty) || eval(`${invoiceNumberComplementary.items.length} ${complementaryOperator} ${complementaryQty}`);

        return hasSearch && hasInvoiceStatus && hasComplementaryQty;
      })
    },

    orderedBonusList() {
      return sortItems(this.filteredBonusList, this.filters.sort.by, this.filters.sort.desc, 'pt-BR');
    },

    /**
     * Verifica se todos os registros da lista estão bloqueados
     * Usado no icone do botão que bloqueia os registros
     * @returns {Boolean}
     */
    isFinancialLocked() {
      return this.producers.length > 0 && this.producers.filter(producer => producer.financialLocked).length === this.producers.length;
    },

    isLocked() {
      return this.producers.some(producer => producer.locked);
    },

    isDairySelected() {
      return !_.isEmpty(this.$store.state.settings.laticinio)
    },

    /**
     * Verifica se existem registros disponíveis para salvar
     * @returns {Boolean}
     */
    hasRowsToSave() {
      return this.producers.some(producer => !!producer.finalPriceEdited || !!producer.ficha_leite)
    },

    /**
     * Recupera o valor base do litro do leite
     * @returns {Number}
     */
    averageBaseValue() {
      if (this.producers.length === 0) {
        return 0.0;
      }

      return _.meanBy(this.producers, "baseAmount");
    },

    /**
     * Recupera o volume total de leite
     * @returns {Number}
     */
    totalVol() {
      if (this.producers.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.producers, 'vol');
    },

    /**
     * Recupera o preço médio do litro do leite
     * @returns {Number}
     */
    milkPriceAvg() {
      if (this.producers.length === 0) {
        return 0.0;
      }

      return this.totalNetAmount / this.totalVol;
    },

    /**
     * Recupera o valor líquido a pagar
     * @returns {Number}
     */
    totalNetAmount() {
      if (this.producers.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.producers, 'totalValue');
    },

    /**
     * Recupera o valor total dos Vales e Descontos
     * @returns {Number}
     */
    totalVouchersAndDiscounts() {
      if (this.producers.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.producers, (producer) => producer.financials.totals.valesAndDiscounts);
    },

    /**
     * Recupera o valor total das taxas
     * @returns {Number}
     */
    totalFees() {
      if (this.producers.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.producers, (producer) => producer.financials.totals.taxes);
    },

    /**
     * Recupera o valor total a pagar (já com os descontos)
     * @returns {Number}
     */
    totalPaidAmount() {
      if (this.producers.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.producers, (producer) => producer.finalValue);
    },

    /**
     * Recupera o role do usuário
     * @returns {String}
     */
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    hasInvoiceAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'financial-producer-invoice' && o.tipo === 'COMPONENTE');
    },

    hasInvoiceDeleteAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'financial-producer-invoice-number-delete' && o.tipo === 'COMPONENTE');
    },

    hasInvoiceNumberAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'financial-producer-invoice-number-insert' && o.tipo === 'COMPONENTE');
    },

    headers() {
      const quality = _.get(this.additionalHeaders, 'quality');
      const others = _.get(this.additionalHeaders, 'others');

      const qualityHeaders = _.map(quality, (additional) => {
        return [
          {
            text: `Resultado ${additional.label}`,
            value: `result.quality.${additional.field}.result`,
            width: 160,
            align: 'center',
            default: false,
            show: false,
          },
          {
            text: `Valor ${additional.label}`,
            value: `result.quality.${additional.field}.amount`,
            width: 160,
            align: 'center',
            default: false,
            show: false,
          }
        ];
      });

      const othersHeaders = _.map(others, (otherAdditional) => {
        const headers = [
          {
            text: `Valor ${otherAdditional.label}`,
            value: `result.others.${otherAdditional.field}.amount`,
            width: 160,
            default: false,
            show: false,
          }
        ];

        if (otherAdditional.type !== 'extra') {
          return [
            {
              text: `Resultado ${otherAdditional.label}`,
              value: `result.others.${otherAdditional.field}.result`,
              width: 160,
              default: false,
              show: false,
            },
            ...headers,
          ];
        }
        return headers;
      });

      return [
        {
          text: "Guid",
          value: "id",
          default: true,
          show: false,
          width: 120,
        },
        {
          align: "center",
          text: "Cód. Produtor",
          value: "producer.code",
          default: true,
          width: 130,
          show: true,
        },
        {
          align: "start",
          text: "Produtor",
          value: "producer.name",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Município",
          value: "producer.county",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Rota Principal",
          value: "producer.route",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Cotação",
          value: "baseAmount",
          width: 110,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        ..._.flatten(qualityHeaders),
        {
          align: "center",
          text: "Qualidade",
          value: "result.totals.quality",
          width: 110,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        ..._.flatten(othersHeaders),
        {
          align: "center",
          text: "Outros",
          value: "result.totals.others",
          width: 100,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Adc. Merc.",
          value: "result.additionals.dairy",
          width: 100,
          sortable: false,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Adc. Téc.",
          value: "result.additionals.technical",
          width: 100,
          sortable: false,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Valor Comp.",
          value: "result.additionals.complementary",
          width: 100,
          sortable: false,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Cotação Paga",
          value: "finalPrice",
          width: 130,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Volume",
          value: "vol",
          width: 100,
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Valor Bruto da Entrega R$",
          value: "totalValue",
          width: 150,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Vales",
          value: "financials.vales",
          width: 140,
          show: false,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Descontos",
          value: "financials.discounts",
          width: 140,
          show: false,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Convênios",
          value: "financials.agreements",
          width: 140,
          show: false,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Antibiótico",
          value: "financials.antibiotics",
          width: 140,
          show: false,
        },
        {
          align: "center",
          text: "Normativa",
          value: "financials.normative",
          width: 140,
          show: false,
        },
        {
          align: "center",
          text: "Crioscopia",
          value: "financials.cryoscopy",
          width: 140,
          show: false,
        },
        {
          align: "center",
          text: "Vales / Descontos",
          value: "financials.totals.valesAndDiscounts",
          width: 150,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Funrural",
          value: "financials.taxes.funrural.amount",
          width: 140,
          show: false,
        },
        {
          align: "center",
          text: "Fundesa",
          value: "financials.taxes.fundesa.amount",
          width: 140,
          show: false,
        },
        {
          align: "center",
          text: "Senar",
          value: "financials.taxes.senar.amount",
          width: 140,
          show: false,
        },
        {
          align: "center",
          text: "Rat",
          value: "financials.taxes.rat.amount",
          width: 140,
          show: false,
        },
        {
          align: "center",
          text: "Administrativa",
          value: "financials.taxes.admin.amount",
          width: 140,
          show: false,
        },
        {
          align: "center",
          text: "Taxas",
          value: "financials.totals.taxes",
          width: 100,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Frete",
          value: "financials.totals.freight",
          width: 100,
          default: false,
          show: false,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "Valor a Pagar R$",
          value: "finalValue",
          width: 125,
          default: true,
          show: true,
          formatter: value => this.formatNumber(value), mask: '#,##0.00',
        },
        {
          align: "center",
          text: "XML",
          value: "existsXml",
          width: 125,
          default: true,
          show: true,
          sortable: false,
        },
        {
          align: "center",
          text: "Nota Fiscal",
          value: "invoice.number",
          width: 125,
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Nota Fiscal Complementar",
          value: "invoiceNumberComplementary",
          width: 125,
          default: true,
          show: true,
          formatter: value => this.getComplementaryInvoicesNumber(value),
        },
        {
          altText: "Conta",
          value: "accountId",
          width: 70,
          default: true,
          sortable: false,
          show: true,
        },
        {
          align: "center",
          text: "CPF",
          value: "bankAccount.holderDocument",
          width: 70,
          default: false,
          sortable: false,
          show: false,
        },
        {
          align: "start",
          text: "Banco",
          value: "bankAccount.bank",
          width: 70,
          default: false,
          sortable: false,
          show: false,
        },
        {
          align: "center",
          text: "Conta Banc.",
          value: "bankAccount.account",
          width: 70,
          default: false,
          sortable: false,
          show: false,
        },
        {
          align: "center",
          text: "Agência Banc.",
          value: "bankAccount.agency",
          width: 70,
          default: false,
          sortable: false,
          show: false,
        },
        {
          align: "start",
          text: "Titular Conta",
          value: "bankAccount.holderName",
          width: 70,
          default: false,
          sortable: false,
          show: false,
        },
        {
          align: "center",
          text: "Forma Pgto.",
          value: "bankAccount.payForm",
          width: 70,
          default: false,
          sortable: false,
          show: false,
        },
        {
          align: "center",
          text: "Tipo Conta",
          value: "bankAccount.type",
          width: 70,
          default: false,
          sortable: false,
          show: false,
        },
        {
          align: "center",
          text: "Nota Produtor",
          value: "producerInvoice",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Ficha Leite",
          value: "ficha_leite",
        },
      ];
    },
  },

  async mounted() {
    const settings = this.$store.state.settings?.gerais || {};
    this.rawMaterialId = this.rawMaterialType === 'PRINCIPAL' ? settings?.id_materia_prima_principal : settings?.id_materia_prima_secundaria;

    this.loadFilters();

    await this.onFilterChange();
  },

  methods: {
    isNull,
    /**
     * Recupera a lista de bonificações
     * @returns {Promise<void>}
     */
    async loadList() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/tabelaPreco/extratoMensalProdutores`,
          {
            mes_referencia: this.filters.dateFilter,
            tipo: this.rawMaterialType,
            id_materia_prima: this.rawMaterialId,
            id_rota: this.filters.route,
            id_regiao: this.filters.region,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        let additionalHeaders = {};
        const producers = data.map((producer, index) => {
          const qualityAdditionals = producer.adicionais_qualidade.map(additional => {
            return {
              field: additional.campo,
              label: additional.label,
              result: additional.resultado,
              amount: additional.valor,
            }
          });

          const othersAdditionals = producer.outros_adicionais.map(additional => {
            return {
              field: additional.campo,
              label: additional.label,
              result: additional.resultado,
              amount: additional.valor,
              type: additional.tipo,
            }
          });

          const qualityHeaders = qualityAdditionals.reduce((headers, additional) => {
            headers[additional.field] = _.omit(additional, ['result', 'amount']);

            return headers;
          }, {});

          const othersHeaders = othersAdditionals.reduce((headers, additional) => {
            headers[additional.field] = _.omit(additional, ['result', 'amount']);

            return headers;
          }, {});

          additionalHeaders = {
            quality: {
              ...additionalHeaders.quality,
              ...qualityHeaders,
            },
            others: {
              ...additionalHeaders.others,
              ...othersHeaders,
            }
          };

          const producerInvoice = JSON.parse(producer.nota_produtor) || {};

          return {
            index,
            id: producer.id_formacao_valor_leite,
            baseAmount: parseFloat(producer.valor_base),
            vol: parseFloat(producer.volume_resultado) || 0,
            producer: {
              id: producer.id_produtor,
              inscricao_estadual: producer.inscricao_estadual,
              code: producer.codigo_laticinio,
              name: producer.nome_produtor,
              route: producer.nome_rota,
              document: producer.cnpj_cpf,
              county: producer.end_cidade
            },
            result: {
              quality: _.keyBy(qualityAdditionals, 'field'),
              others: _.keyBy(othersAdditionals, 'field'),
              additionals: {
                dairy: parseFloat(producer.valor_adicional1) || 0.0,
                technical: parseFloat(producer.valor_adicional2) || 0.0,
                complementary: parseFloat(producer.valor_complementar) || 0.0,
              },
              totals: {
                quality: parseFloat(producer.valor_bonificacao_qualidade) || 0,
                others: parseFloat(producer.valor_bonificacao_adicional) || 0,
              },
            },
            financials: {
              vales: parseFloat(producer.valor_vales) || 0.0,
              cryoscopy: parseFloat(producer.valor_crioscopia) || 0.0,
              discounts: parseFloat(producer.valor_descontos) || 0.0,
              agreements: parseFloat(producer.valor_convenios) || 0.0,
              antibiotics: parseFloat(producer.valor_antibiotico) || 0.0,
              normative: parseFloat(producer.valor_normativa) || 0.0,
              taxes: {
                funrural: {
                  label: "Funrural",
                  amount: parseFloat(producer.taxa_funrural) || 0.0,
                },
                fundesa: {
                  label: "Fundesa",
                  amount: parseFloat(producer.taxa_fundesa) || 0.0,
                },
                senar: {
                  label: "Senar",
                  amount: parseFloat(producer.taxa_senar) || 0.0,
                },
                rat: {
                  label: "Rat",
                  amount: parseFloat(producer.taxa_rat) || 0.0,
                },
                admin: {
                  label: "Administrativa",
                  amount: parseFloat(producer.taxa_administrativa) || 0.0,
                },
              },
              totals: {
                taxes: parseFloat(producer.valor_taxas) || 0,
                freight: parseFloat(producer.valor_frete) || 0,
                valesAndDiscounts: parseFloat(producer.valor_vale_descontos) || 0,
              },
            },
            finalPrice: parseFloat(producer.valor_unitario_final) || 0.0,
            totalValue: parseFloat(producer.valor_entrega) || 0.0,
            finalValue: parseFloat(producer.valor_a_pagar) || 0.0,
            accountId: producer.id_titulo,
            invoice: {
              id: producer.id_nota_fiscal,
              number: producer.numero_nfe,
              type: producer.tipo_nfe,
              status: producer.status_nfe,
              emissionDate: producer.data_emissao_nfe,
            },
            producerInvoice: {
              id: producer.id_nfpe,
              number: producerInvoice.numero,
              series: producerInvoice.serie,
              emissionDate: producerInvoice.emissao,
            },
            existsXml: producer.existe_xml,
            invoiceNumberComplementary: {
              items: JSON.parse(producer.complementos || '[]').map(item => ({
                amount: item.valor,
                invoice: item.numero_nfe,
                id: item.id_complemento,
                locked: !!item.numero_nfe,
              })),
              show: false,
            },
            bankAccount: {
              bank: producer.dados_forma_pagamento.banco,
              account: producer.dados_forma_pagamento.conta,
              agency: producer.dados_forma_pagamento.agencia,
              holderName: producer.dados_forma_pagamento.nome_titular,
              holderDocument: producer.dados_forma_pagamento.cnpj_cpf || producer.cnpj_cpf,
              payForm: producer.dados_forma_pagamento.forma_pagamento,
              type: AccountTypesEnum[producer.dados_forma_pagamento.tipo_conta] || '',
            },
            locked: producer.status === 1,
            invoiceLocked: producer.status_financeiro === 'CONCLUIDO' || !!producer.numero_nfe,
            financialLocked: producer.status_financeiro === 'CONCLUIDO',
            editFinalPrice: false,
            finalPriceEdited: null,
            ficha_leite: producer.ficha_leite,
          };
        });

        this.additionalHeaders = additionalHeaders;
        this.producers = producers;
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    replicarValor() {
      const valor = this.filteredBonusList[0].ficha_leite;

      this.filteredBonusList.forEach(item => {
        this.$set(item, 'ficha_leite', valor);
      });
    },

    /**
     * Recupera o valor base do preço do leite
     * @param item {Object}
     * @returns {Number}
     */
    getRowBaseAmount(item) {
      if (item.finalPriceEdited) {
        return parseFloat(item.finalPriceEdited)
      }

      return item.baseAmount;
    },

    /**
     * Recupera o valor final do preço do leite
     * @param item {Object}
     * @returns {Number}
     */
    getRowFinalPrice(item) {
      if (item.finalPriceEdited) {
        return parseFloat(item.finalPriceEdited)
      }

      return item.finalPrice;
    },

    /**
     * Recupera o valor líquido de entrega
     * @param item {Object}
     * @returns {Number}
     */
    getRowTotalPrice(item) {
      // Fixa em 4 casas decimais o cálculo
      return item.vol * parseFloat(this.getRowFinalPrice(item));
    },

    download() {
      const date = moment().format('DD/MM/YYYY');

      const contents = this.orderedBonusList.map(o => {
        return [
          String(o.producer.code).padEnd(7),
          String(date).padStart(10),
          String(o.invoice.number).padStart(6),
          String((o.totalValue || 0.0).toFixed(2)).padStart(9),
          String((o.financials.taxes.funrural.amount || 0.0).toFixed(2)).padStart(9),
          String('0.00').padStart(9),
          String((o.financials.taxes.admin.amount || 0.0).toFixed(2)).padStart(9),
          String('0.00').padStart(9),
          String('0.00').padStart(9),
          String('0.00').padStart(9),
          String((o.finalPrice || 0.0).toFixed(4)).padStart(8),
          String((o.financials.taxes.senar.amount || 0.0).toFixed(2)).padStart(9),
        ].join(' ');
      }).join('\r\n');

      let pp = document.createElement('a');
      pp.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(contents));
      pp.setAttribute('download', `Fechamento de Contas Produtores - ${moment(this.filters.dateFilter, 'YYYY-MM').format('MM-YYYY')}.txt`);
      pp.click();
    },

    onDateFilterChange() {
      this.loadFilters();
      this.onFilterChange();
    },

    /**
     * @event void
     *
     * Evento acionado para refazer a lista com base nos novos filtros
     */
    onFilterChange: _.debounce(function() {
      this.loadList();

      this.selectedProducers = [];
      this.confirmBillDialog.startDate = moment(this.filters.dateFilter, 'YYYY-MM').startOf('month').toDate();
      this.confirmBillDialog.endDate = moment(this.filters.dateFilter, 'YYYY-MM').endOf('month').toDate();
    }, 1000),

    /**
     * @event string
     *
     * Evento acionado ao selecionar uma coluna para exibir na lista
     */
    onHeaderSelect(value) {
      this.headers = this.headers.map(header => {
        if (header.value === value || value === '*') {
          return {
            ...header,
            show: !header.show || header.default,
          }
        }

        return header;
      })
    },

    async showGenerateFinancialDialog() {
      if (this.rawMaterialType === 'SECUNDARIA') {
        this.generateFinancial();
        return;
      }
      this.confirmBillDialog.show = true;
    },

    async generateFinancial() {
      try {
        this.confirmBillDialog.show = false;
        this.loading = true;

        const bonificacao = this.producers.map(bonus => {
          return {
            id_formacao_valor_leite: bonus.id,
            numero_nfe: bonus.invoice.number,
            complementos: bonus.invoiceNumberComplementary.items.map(item => ({
              id_complemento: item.id,
              valor: item.amount,
              numero_nfe: item.invoice,
            })),
          }
        });
        const { data } = await this.$axios.post(
          `/financeiro/gerarFinanceiroProdutor`,
          {
            mes_referencia: this.filters.dateFilter,
            tipo: this.rawMaterialType,
            id_materia_prima: this.rawMaterialId,
            id_rota: this.filters.route,
            data_inicial_contas: moment(this.confirmBillDialog.startDate).format('YYYY-MM-DD'),
            data_final_contas: moment(this.confirmBillDialog.endDate).format('YYYY-MM-DD'),
            quitar_contas: this.confirmBillDialog.payOffBills,
            bonificacao
          }
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Financeiro gerado com sucesso");

        return this.loadList();
      } catch (err) {
        this.loading = false;
        const message = _.get(err, 'message', 'Erro ao gerar financeiro');
        this.$snotify.error(message, 'Atenção');

        console.warn(err);
      }
    },

    /**
     * Action responsável por salvar as bonificações que estão na tela
     * @returns {Promise<void>}
     */
    async save() {
      try {
        this.loading = true;

        const changedFinalPrince = this.producers.filter(bonus => parseFloat(bonus.finalPriceEdited));

        if (!isEmpty(changedFinalPrince)) {
          if (!(await this.$root.$confirm(
            'Atenção',
            `Esta ação irá descartar <b>TODOS</b> os valores adicionais de bonificação dos itens modificados.<br>`,
            { color: 'red', token: 'CONFIRMAR' }
          ))
          ) {
            return;
          }
        }

        const bonificacao = this.producers.map(bonus => {

          if (bonus.locked && !this.isAdmin) {
            return;
          }

          const finalPriceEdited = parseFloat(bonus.finalPriceEdited);
          const fichaLeite = bonus.ficha_leite;

          if (!finalPriceEdited && !fichaLeite) {
            return;
          }

          const dados = {
            data_referencia: moment(this.filters.dateFilter, "YYYY-MM").format("YYYY-MM-01"),
            id_materia_prima: this.rawMaterialId,
            id_produtor: bonus.producer.id,
            nome_produtor: bonus.producer.name,
            codigo_laticinio: bonus.producer.code,
            id_formacao_valor_leite: bonus.id,
            status: Number(this.locked),
            ficha_leite: bonus.ficha_leite,
          }

          if (!finalPriceEdited) {
            return dados;
          }

          return {
            ...dados,

            /**
             * Zera todos os valores adicionais pois foi alterado o preço final
             */
            // Qualidade
            valor_adicional_ccs: 0,
            valor_adicional_cbt: 0,
            valor_adicional_esd: 0,
            valor_adicional_proteina: 0,
            valor_adicional_gordura: 0,
            valor_adicional_caseina: 0,
            valor_adicional_ureia: 0,
            valor_adicional_solidos_totais: 0,
            valor_adicional_lactose: 0,

            // Outros
            valor_adicional_volume: 0,
            valor_adicional_km: 0,
            valor_adicional_bpa: 0,
            valor_adicional_fidelidade: 0,
            valor_adicional_brucelose: 0,
            valor_adicional_tuberculose: 0,

            // Totais
            valor_bonificacao_qualidade: 0,
            valor_bonificacao_adicional: 0,

            valor_qualidade: 0,

            valor_adicional1: 0,
            valor_adicional2: 0,
            valor_complementar: 0,

            valor_base: finalPriceEdited,
            valor_unitario_final: finalPriceEdited,
            valor_entrega: this.getRowTotalPrice(bonus),
            valor_a_pagar: null,

            alterado_valor_unitario_final: true,
          }
        }).filter(b => b);

        if (_.isEmpty(bonificacao)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/tabelaPreco/salvaBonificacao`,
          bonificacao,
        );

        if (!_.isObject(data) || !data.codigo) {
          throw "PHP Error";
        }

        this.$snotify.success("Sucesso, dados salvos!");

        if (!isEmpty(changedFinalPrince) && this.producers.some(item => !!item.finalValue)) {
          this.showGenerateFinancialDialog();
        }
        else {
          this.loadList();
        }

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Action responsável por concluir/liberar o lançamento de NFs
     * @returns {Promise<void>}
     */
    async changeStatus() {
      const confirmMessage = !this.isFinancialLocked
        ? `Deseja realmente liberar para que o ERP gere as NF-e?<br><br>
          <div class="v-alert v-sheet v-sheet--outlined theme--light elevation-0 v-alert--dense v-alert--outlined blue--text body-2">
            Após gerado as NF-e, não será possível fazer qualquer edição/ajuste de cotação, volumes, amostras para estes produtores com notas.
          </div>
          `
        : `Deseja realmente reabrir para edição?<br><br>
        <div class="v-alert v-sheet v-sheet--outlined theme--light elevation-0 v-alert--dense v-alert--outlined blue--text body-2">
          Apenas para produtores sem notas geradas, será possível fazer edição/ajustes.
        </div>
        `;

      if (!(await this.$root.$confirm('Atenção', confirmMessage, { color: 'red', token: 'CONFIRMAR' }))) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/financeiro/statusFinanceiroProdutor`, {
          mes_referencia: this.filters.dateFilter,
          tipo: this.rawMaterialType,
          id_materia_prima: this.rawMaterialId,
          rotas: this.filters.route,
          status: this.isFinancialLocked ? 'GERANDO' : 'CONCLUIDO'
        });

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Status alterado com sucesso");

        return this.loadList();
      } catch (err) {
        this.$snotify.error("Erro ao alterar status", "Atenção");

        console.warn(err);
        this.loading = false;
      }
    },

    applyInvoiceSequence(item) {
      let invoiceNumber = parseInt(item.invoice.number) || 0;

      if (!invoiceNumber) {
        return;
      }

      /**
       * orderedBonusList é a lista ordenada na tabela
       */
      const index = this.orderedBonusList.findIndex(producerBonus => producerBonus.id === item.id);

      /**
       * Cria um objeto com o numero da nota a ser aplicada para cada id da bonificação
       */
      const producerInvoices = this.orderedBonusList
        .slice(index)
        .reduce((acc, cur) => ({ ...acc, [cur.id]: invoiceNumber++ }), 0);

      this.producers = this.producers.map(producerBonus => {
        return {
          ...producerBonus,
          invoice: {
            ...producerBonus.invoice,
            number: producerInvoices[producerBonus.id] || producerBonus.invoice.number,
          }
        }
      });
    },

    showBill(item) {
      this.billDialog.type = item.finalValue < 0 ? 'VALE' : 'DESCONTO';
      this.billDialog.id = item.accountId;
      this.billDialog.show = true;
    },

    getComplementaryInvoicesNumber(value) {
      return value.items
        .filter(item => !!item.invoice)
        .map(item => item.invoice)
        .join(', ');
    },

    async issueFiscalInvoice() {
      this.$refs.invoiceProcessingDialog.show({
        type: 'ENTRADA',
        bonusIds: this.selectedProducers.map(row => row.id),
        invoiceIds: this.selectedProducers.filter(row => row.invoice.id).map(row => row.invoice.id)
      })
    },

    async issueNFPe() {
      this.$refs.invoiceProcessingDialog.show({
        type: 'NFPE',
        bonusIds: this.selectedProducers.map(row => row.id),
        invoiceIds: this.selectedProducers.filter(row => row.producerInvoice.id).map(row => row.producerInvoice.id)
      })
    },

    onAuthorize(invoices) {
      this.$refs.batchAuthDialog.show(invoices);
    },

    async clearInvoiceNumber() {
      try {
        this.loading = true;

        if (_.isEmpty(this.selectedProducers)) {
          return;
        }

        if (!(await this.$root.$confirm(
          'Apagar número de notas fiscais?',
          `Esta ação não poderá ser desfeita<br>`,
          { color: 'red', token: 'CONFIRMAR' }
        ))) {
          return;
        }

        const ids = this.selectedProducers.map(row => row.id);

        const { data } = await this.$axios.post(
          `/financeiro/apagarNumeroNfeFechamento`,
          {
            mes_referencia: this.filters.dateFilter,
            ids
          }
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success('Operação efetuada com sucesso');
      } catch (e) {
        console.warn(e);

        this.$snotify.error(
          'Oops, ocorreu um erro ao apagar os números das notas fiscais!',
          'Atenção'
        );
      } finally {
        this.loading = false;
        this.loadList();
      }
    },

    showPrintDialog() {
      this.$refs['print-settings'].show({
        params: [
          { title: 'Recibo', key: 'mostra_recibo', value: true },
          { title: 'Notas Fiscais', key: 'mostra_nota_fiscal', value: false },
          {
            title: 'Resumo',
            key: 'tipo_resumo',
            value: 'PADRAO',
            type: 'SELECT',
            items: [
              { value: 'PADRAO', text: 'Padrão' },
              { value: 'COTACAO', text: 'Apenas Cotação' },
              { value: 'COMPLEMENTOS', text: 'Apenas Complementos' },
            ],
          },
        ]
      });
    },

    async print({ params }) {
      try {
        this.loading = true;

        if (_.isEmpty(this.selectedProducers)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/tabelaPreco/imprimeFechamentoProdutor`,
          {
            ids: this.selectedProducers.map(row => row.id),
            params,
          },
          { responseType: 'blob' }
        );

        const dataObject = window.URL.createObjectURL(data);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = `Recibo Produtor - ${moment(this.filters.dateFilter, 'YYYY-MM').format('MM-YYYY')}.pdf`;
        link.click();

        window.URL.revokeObjectURL(dataObject);
        link.remove();

      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir as bonificações!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async printByRoute() {
      try {
        this.loading = true;

        if (_.isEmpty(this.producers)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/tabelaPreco/imprimeExtratoMensalPorRotas`,
          {
            mes_referencia: this.filters.dateFilter,
            tipo: this.rawMaterialType,
            id_materia_prima: this.rawMaterialId,
            id_rota: this.filters.route,
          }
        );

        if (data.codigo === 0) {
          throw data.mensagem;
        }

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir as bonificações!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async loadFilters() {
      try {
        const { data } = await this.$axios.post(`/tabelaPreco/filtrosBonificacao`, {
          mes_referencia: this.filters.date,
          tipo: this.rawMaterialType,
          id_materia_prima: this.rawMaterialId,
        });

        this.filters.routes = data.rotas;
        this.filters.regions = data.regioes;
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os filtros!", 'Atenção');
      }
    },

    getReportTitle() {
      return `Fechamento - ${moment(this.filters.dateFilter, 'YYYY-MM').format('MM-YYYY')}`;
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    printReport() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title, true);
    },

    async updateTable() {
      await this.loadList();
    },

    showInvoice(type, id) {
      this.invoiceDialog.show = true;
      this.invoiceDialog.type = type;
      this.invoiceDialog.id = id;
    },

    applyMask: (value, mask) => IMask.pipe(String(value), mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED),

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),

    formatCurrency: (value, decimals = 4) => {
      const formatter = new Intl.NumberFormat(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
          maximumFractionDigits: decimals
        }
      );

      return formatter.format(value || 0);
    },

    getDisabledStyle() {
      return { background: 'rgba(255, 255, 255, 0.12) !important', cursor: 'not-allowed' }
    },

    getInvoiceColor(value) {
      const statusColors = {
        'PENDENTE': 'amber darken-1',
        'PROCESSANDO': 'blue',
        'REJEITADA': 'red',
        'EMITIDA': 'green',
        'CANCELADA': 'grey',
        'DENEGADA': 'red darken-1',
        'CONTIGENCIA': 'teal',
      };
      return statusColors[value] || 'grey';
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),

  },
};
</script>
