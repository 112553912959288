
/**
 * Coletas
 */

// Relatórios de Coleta
import ColetaVolume from "@/Domains/Collects/Reports/CollectedVol/Index.vue";
import DailyCollection from "@/Domains/Collects/Reports/DailyCollection.vue";
import ColetaTotalLeite from "@/Domains/Collects/Reports/MilkTotal.vue";
import CollectionVolumeCity from "@/Domains/Collects/Reports/CollectionVolumeByCity/Index.vue"
import CollectionVolumeRegion from "@/Domains/Collects/Reports/CollectionVolumeRegion.vue"
import RegionProducerReport from "@/Domains/Collects/Reports/RegionProducerReport.vue"
import ProducerNotCollected from "@/Domains/Collects/Reports/ProducerNotCollected.vue"
import CooperativeVolume from "@/Domains/Collects/Reports/CooperativeVolume/Index.vue"
import CollectionImpediment from "@/Domains/Collects/Reports/CollectionImpediment/Index.vue"
import CollectionVolumeByRoute from "@/Domains/Collects/Reports/CollectionVolumeByRoute/Index.vue";
import ProducerCollected from "@/Domains/Collects/Reports/ProducerCollected/Index.vue"
import CollectionMilk from "@/Domains/Collects/Reports/CollectionMilk.vue";
import VolumeRejected from "@/Domains/Collects/Reports/VolumeRejected/Index.vue";
import CollectionNormative from "@/Domains/Collects/CollectionNormative/Index.vue";
import RoutesMapReport from "@/Domains/Collects/Reports/RoutesMapReport.vue";
import FleetVolume from "@/Domains/Collects/Reports/FleetVolume/Index.vue";
import VolumeProductionDeviation from "@/Domains/Collects/Reports/VolumeProductionDeviation.vue"
import CondominiumVolume from "@/Domains/Collects/Reports/GroupingVolume/CondominiumVolume.vue"
import CommunityTankVolume from "@/Domains/Collects/Reports/GroupingVolume/CommunityTankVolume.vue"
import FamilyGroupVolume from "@/Domains/Collects/Reports/GroupingVolume/FamilyGroupVolume.vue"

// Gestão de coletas
import FreightPayment from "@/Domains/Collects/Management/FreightPayment.vue";
import FreightValueTable from "@/Domains/Collects/Management/FreightValueTable/Index.vue";
import FreightMonthPayment from "@/Domains/Collects/Management/FreightMonthPayment.vue";
import FreightTransfersPayment from "@/Domains/Collects/Management/FreightTransfersPayment.vue";
import FreightMapReport from "@/Domains/Collects/Management/FreightMapReport.vue";

// Coleta de amostras
import ColetaAntibioticos from "@/Domains/Collects/Samples/Index.vue";

// Simulador de ganho
import ColetaSimulador from "@/Domains/Collects/Simulator/Index.vue";

/**
 * Rotas
 */
import ColetaRota from "@/Domains/Routes/Index.vue";

/**
 * Visitas
 */

// Recomendações
import QualityIndicatorsVisits from "@/Domains/Visits/Recommendations/Index.vue";
import ScheduledRecommendations from "@/Domains/Visits/ScheduledRecommendations/Index.vue";

// Relatórios de Visitas
import OtherActivities from "@/Domains/Visits/Reports/Views/OtherActivities.vue";
import ColetaTotalLeiteTecnico from "@/Domains/Visits/Reports/Views/ProductionByTechnical.vue";
import ColetaVisitaTecnico from "@/Domains/Visits/Reports/Views/TechnicalVisitList.vue";
import ScheduledVisited from "@/Domains/Visits/Reports/ScheduledVisited/Index.vue";

// Agenda
import Schedule from "@/Domains/Visits/Schedule/Index.vue";

import VisitPayment from "@/Domains/Visits/Itineraries/VisitPayment.vue";
import VisitsGoals from "@/Domains/Visits/Goals/Index.vue";

/**
 * Itinerario
 */
// Coleta
import ColetaItinerario from "@/Domains/Itineraries/ApprovedCollectsItineraries.vue";
import ItineraryWaitingQueue from "@/Domains/Itineraries/PendingCollectsItineraries.vue";
import VisitaItinerario from "@/Domains/Itineraries/VisitsItineraries.vue";
import TransferenciaItinerario from "@/Domains/Itineraries/TransfersItineraries.vue";
import SpotItinerario from "@/Domains/Itineraries/SpotItineraries.vue";
import ItineraryLogReport from "@/Domains/Itineraries/ItineraryLogReport.vue";

/**
 * Produtores
 */
// Bonificações
import Bonificacao from "@/Domains/Producer/Bonus/BonusSpreadsheet/Index.vue";
import BonusCriteria from "@/Domains/Producer/Bonus/BonusCriteria/Index.vue";
import BonusDifferences from "@/Domains/Producer/Bonus/BonusDifferences/Index.vue";

// Bonificações 2
import OtherMilkBonus from "@/Domains/Producer/Bonus/BonusSpreadsheet/OtherMilkBonus.vue";
import OtherBonusCriteria from "@/Domains/Producer/Bonus/BonusCriteria/OtherCriteria.vue";

// Gerenciamento
import ActiveProducers from "@/Domains/Producer/Management/Views/ActiveProducers.vue";
import ProducerProspections from "@/Domains/Producer/Management/Views/ProducerProspections.vue";
import SuspendedProducer from "@/Domains/Producer/Management/Views/SuspendedProducers.vue";
import UnlinkedProducer from "@/Domains/Producer/Management/Views/UnlinkedProducers.vue";
import PendingProducers from "@/Domains/Producer/Management/Views/PendingProducers.vue";

import OwnershipChange from "@/Domains/Producer/OwnershipChange/Index.vue";

/**
 * Plataforma
 */
// Silos
import ColetaCadastroSilo from "@/Domains/Platform/Silos/Index.vue";

// Descarregamento
import PlatformUnload from "@/Domains/Platform/Unload/Index.vue";

// Spot
import Spots from "@/Domains/Platform/Spots/Index.vue";

// Relatorios
import DiffMeasuredVale from "@/Domains/Platform/Reports/DiffMeasuredVale.vue";
import DiffMeasuredValeByRoute from "@/Domains/Platform/Reports/DiffMeasuredValeByRoute.vue";
import SiloScreening from "@/Domains/Platform/Reports/SiloScreening.vue";
import DiscardNonconformities from "@/Domains/Platform/Reports/DiscardNonconformities.vue";
import PeriodicSiloAnalysis from "@/Domains/Platform/PeriodicSiloAnalysis/Index.vue";
import PlatformProducerWaterDiscount from "@/Domains/Platform/Reports/PlatformProducerWaterDiscount.vue";
import RawMaterialReport from "@/Domains/Platform/Reports/RawMaterialReport.vue";
import ProducerTankAnalysis from "@/Domains/Platform/Reports/ProducerTankAnalysis.vue";
import SiloHistoryFlow from "@/Domains/Platform/Reports/SiloHistoryFlow.vue";
import UnitTransfersReport from "@/Domains/Platform/Reports/UnitTransfersReport.vue";
import SpotsPlannedRealized from "@/Domains/Platform/Reports/Views/SpotsPlannedRealized.vue";

//analises
import RoutineAnalysis from "@/Domains/Platform/RoutineAnalysis/index.vue";

/**
 * Qualidade
 */
//Plano de Qualificação e Ação
import RankingPqfl from "@/Domains/Quality/PlanQualification/Views/RankingPqfl.vue";
import DiagnosticRecord from "@/Domains/Quality/PlanQualification/Views/DiagnosticRecord.vue";

// Indicadores
import ColetaImpactoVolume from "@/Domains/Quality/Indicators/Views/ImpactOnVol.vue";
import ColetaMediaQualidadeMes from "@/Domains/Quality/Indicators/Views/QualityAverageMonth.vue";
import AverageOfQuality from "@/Domains/Quality/Indicators/AverageOfQuality/Index.vue";
import MediaAnalise from "@/Domains/Quality/Indicators/Views/MediaAnalise.vue";
import QualityOutOfNormative from "@/Domains/Quality/Indicators/Views/QualityOutOfNormative.vue";
import QualityCbtAverage from "@/Domains/Quality/Indicators/Views/QualityCbtAverage.vue";
import QualityCbtCritical from "@/Domains/Quality/Indicators/Views/QualityCbtCritical.vue";
import QualityPriorityRanking from "@/Domains/Quality/Indicators/Views/QualityPriorityRanking.vue";
import QualityStandard from "@/Domains/Quality/Indicators/Views/QualityStandard.vue";
import PriorityVisits from "@/Domains/Quality/Indicators/Views/PriorityVisits.vue";
import ProducerRating from "@/Domains/Quality/Indicators/Views/ProducerRating.vue";
import PqflChecklistReport from "@/Domains/Quality/Indicators/Views/PqflChecklistReport.vue";
import PqflBpaReport from "@/Domains/Quality/Indicators/Views/PqflBpaReport.vue";
import ReportSuspendedProducers from "@/Domains/Quality/Indicators/SuspendedProducers/ReportSuspendedProducers.vue";
import HealthExamReport from "@/Domains/Quality/Indicators/Views/HealthExamReport.vue";
import PriorityVolumeImpact from "@/Domains/Quality/Indicators/Views/PriorityVolumeImpact.vue";
import ProducerRatingIndicators from "@/Domains/Quality/Indicators/Views/ProducerRatingIndicator.vue";
import DairyVisitsGoals from "@/Domains/Projects/Visits/VisitsReportDetails.vue";

// Qualificação
import CollectionsPqlf from "@/Domains/Quality/Qualification/CollectionsPQFL/Index.vue";

//Questionário
import VisitsQuestionnaires from "@/Domains/Questionnaires/Visits.vue"
import OthersQuestionnaires from "@/Domains/Questionnaires/Others.vue"
/**
 * Análises
 */
import ErrorLogsAnalysis from "@/Domains/Analysis/Logs/ErrorLogsAnalysis.vue";

import AnalysisPreValidation from "@/Domains/Analysis/PreValidation/Views/AnalysisPreValidation.vue";

// Produtores
import ColetaAnalisesProdutor from "@/Domains/Analysis/Producers/Views/ProducerAnalysis.vue";
import ProducerWithoutAnalysis from "@/Domains/Analysis/Producers/Views/ProducersWithoutAnalysis.vue";

// Silos
import ColetaAnalisesSilo from "@/Domains/Analysis/Silos/Views/SilosAnalysis.vue";

// Tanques
import ColetaAnalisesTanque from "@/Domains/Analysis/Tanks/Views/TankAnalysis.vue";

// Upload
import UploadAnalysis from "@/Domains/Analysis/Upload/UploadAnalysis.vue";

import SampleOrderControl from "@/Domains/Analysis/SampleOrderControl/Index.vue";
import SampleOrderControlReport from "@/Domains/Analysis/SampleOrderControl/Report.vue";

// Configuracoes
import Settings from "@/Domains/Settings/Index.vue";

// Cadastros
import CooperativeRegistration from "@/Domains/Registrations/Person/Views/CooperativeRegistration.vue";
import FleetRegistration from "@/Domains/Registrations/Fleet/Index.vue";
import UserRegistration from "@/Domains/Registrations/User/Index.vue";
import SupplierRegistration from "@/Domains/Registrations/Person/Views/SupplierRegistration.vue";
import CustomerRegistration from "@/Domains/Registrations/Person/Views/CustomerRegistration.vue";
import DairyRegistration from "@/Domains/Registrations/Person/Views/DairyRegistration.vue";
import RegionRegistration from "@/Domains/Registrations/Region/Index.vue";
import CondominiumRegistration from "@/Domains/Registrations/Person/Views/CondominiumRegistration.vue";
import CompetingDairyRegistration from "@/Domains/Registrations/Person/Views/CompetingDairyRegistration.vue";
import DocumentsRegistration from "@/Domains/Registrations/Documents/Index.vue";
import CommunityTankRegistration from "@/Domains/Registrations/Person/Views/CommunityTankRegistration.vue";
import FamilyGroupRegistration from "@/Domains/Registrations/Person/Views/FamilyGroupRegistration.vue";
import AnalysisTypeRegistration from "@/Domains/Registrations/Analysis/Type/Index.vue";
import AnalysisProductRegistration from "@/Domains/Registrations/Analysis/Product/Index.vue";
import LaboratoryRegistration from "@/Domains/Registrations/Analysis/Laboratory/Index.vue";
import ProductionStepRegistration from '@/Domains/Registrations/ProductionStep/Index.vue'
import TinaIndex from "@/Domains/Registrations/Tina/TinaIndex.vue";
import CryoscopyIndex from "@/Domains/Registrations/Cryoscopy/CryoscopyIndex.vue";

// Cadastro de Produtos
import Item from "@/Domains/Registrations/Item/Index.vue";
import Warehouse from "@/Domains/Registrations/Warehouse/Index.vue";

// Expedição
import StorageControl from "@/Domains/Shipment/StorageControl/Index.vue";
import ShippingItineraries from "@/Domains/Shipment/ShippingItineraries/Index.vue";
import Shipment from "@/Domains/Shipment/Shipment/Index.vue";
import ShipmentStockControl from "@/Domains/Shipment/Reports/LotStockControl.vue";
import ShipmentStockTracking from "@/Domains/Shipment/Reports/LotStockTracking.vue";

// Mapa
import Mapa from "@/Domains/Map/Index.vue";

// Notificaçõesprojeto
import Notifications from "@/Domains/Notifications/Index.vue";

// Projetos
import ProjectPmlsProducer from "@/Domains/Projects/PMLS/ProducersGoals.vue";
import ProjectPmlsFinancial from "@/Domains/Projects/PMLS/Financial.vue";
import ProjectPmlsChecklist from "@/Domains/Projects/PMLS/Questionnaires/Index.vue";
import ProjectPmlsReport from "@/Domains/Projects/PMLS/PmlsReport.vue";
import ProjectPqflPae from "@/Domains/Projects/PQFL/PAE/Index.vue";
import ProjectPqflBpa from "@/Domains/Projects/PQFL/BPA/Index.vue";
import ProjectPqflChecklist from "@/Domains/Projects/PQFL/Questionnaires/Index.vue";
import ProjectPqflVisits from "@/Domains/Projects/PQFL/Views/Visits.vue";
import HealthExamination from "@/Domains/Projects/HealthExamination/Index.vue";
import PqflReport from "@/Domains/Projects/PQFL/Views/GeneralReport.vue";
import ProducerRatingGoals from "@/Domains/Projects/Dairy/ProducerRating/Index.vue";
import DairyQualityGoals from "@/Domains/Projects/Dairy/QualityGoals/Index.vue";
import DairyVisits from "@/Domains/Projects/Dairy/Views/Visits.vue";
import ProjectDairyChecklist from "@/Domains/Projects/Dairy/Questionnaires/Index.vue";
import ProjectPmlsVisits from "@/Domains/Projects/PMLS/Views/Visits.vue";
import AwardPoints from "@/Domains/Projects/Dairy/AwardPoints/Index.vue";
import ProjectCompativeEvolution from "@/Domains/Projects/PQFL/Comparative/Checklists.vue";
import FamilyGroupRegistrationProject from "@/Domains/Registrations/Person/Views/FamilyGroupRegistrationProject.vue";

// Configuracoes milkup
import SettingsMilkup from "@/Domains/SettingsMilkup/Index.vue";

// Business Inteligence
import Dashboard from "@/Domains/BI/Index.vue";

// Sorteio para analises complementares
import AnalysisPeriodic from "@/Domains/Analysis/Periodic/Index.vue";

// Pedidos
import ProducersOrders from "@/Domains/Orders/Views/ProducersOrders.vue";
import BusinessOrders from "@/Domains/Orders/Views/BusinessOrders.vue";

// Financeiro
import BillsToPay from "@/Domains/Financial/Views/BillsToPay.vue";
import BillsToReceive from "@/Domains/Financial/Views/BillsToReceive.vue";
import ProducerVouchers from "@/Domains/Financial/Producer/Index.vue";
import ProducerCovenants from "@/Domains/Financial/Views/ProducerCovenants.vue";
import CashFlow from "@/Domains/Financial/Views/CashFlow.vue";
import ProducersValeDiscounts from "@/Domains/Financial/Views/ProducersValeDiscounts.vue";
import OtherProducersValeDiscounts from "@/Domains/Financial/Views/OtherProducersValeDiscounts.vue";
import FreighterVouchers from "@/Domains/Financial/Freighter/Index.vue";
import FinancialSettings from "@/Domains/Financial/Settings/Index.vue";
import MilkDiscounts from "@/Domains/Financial/MilkDiscounts/Index.vue"
import ProducerDiscounts from "@/Domains/Financial/ProducerDiscounts/Index.vue"
import ProducerStatement from "@/Domains/Financial/Reports/ProducerStatement.vue"
import SupplierStatement from "@/Domains/Financial/Reports/SupplierStatement.vue"
import ControlExpenses from "@/Domains/Financial/ControlExpenses/Index.vue"
import DriverExpenses from "@/Domains/Financial/Reports/DriverExpenses.vue"
import ExpensesByItem from "@/Domains/Financial/Reports/ExpensesByItem.vue"
import MilkCost from "@/Domains/Financial/Reports/MilkCost/Index.vue"
import ProducerEndPeriodBonus from "@/Domains/Financial/ProduerEndPeriodBonus/Index.vue"

//Comercial
import CommercialMap from "@/Domains/Commercial/Map/Index.vue"
import CommercialItinerary from "@/Domains/Commercial/Itinerary/Index.vue"
import CommercialRoutes from "@/Domains/Commercial/Routes/Index.vue"
import ProductStock from "@/Domains/Commercial/ProductStock/Index.vue"
import CommercialDelivery from "@/Domains/Commercial/Delivery/Index.vue"
import PriceTable from "@/Domains/Commercial/PriceTable/Index.vue"
import SalesCommission from "@/Domains/Commercial/SalesCommission/Index.vue"

//Frota
import FleetMaintenance from "@/Domains/Fleet/Maintenance/Index.vue"

//Indústria
import ProductionOrder from "@/Domains/Industry/ProductionOrder/Index.vue"
import ProductionOrderIndicators from "@/Domains/Industry/Indicators/Index.vue"
import ProductionPlanning from "@/Domains/Industry/ProductionPlanning/Index.vue"
import LotStockControl from "@/Domains/Industry/Reports/LotStockControl.vue";

//Fiscal
import OutgoingInvoice from "@/Domains/Fiscal/Views/OutgoingInvoice.vue";
import IncomingInvoice from "@/Domains/Fiscal/Views/IncomingInvoice.vue";
import NfpeInvoice from "@/Domains/Fiscal/Views/NfpeInvoice.vue";
import ProducersNfpeReport from "@/Domains/Fiscal/Views/ProducersNfpeReport.vue";

//Auditoria
import Audit from "@/Domains/Audit/Index.vue";

//servicos veterinarios / agronomicos / Visitas Definidas
import VeterinaryCareCauses from "@/Domains/Registrations/Cares/Cause/VeterinaryCauses.vue";
import VeterinaryCareIndicators from "@/Domains/Registrations/Cares/Indicator/VeterinaryIndicator.vue";
import VeterinaryCareService from "@/Domains/Registrations/Cares/Service/VeterinaryService.vue";
import VeterinaryCareRecommendation from "@/Domains/Registrations/Cares/Recommendation/VeterinaryRecommendation.vue";
import VeterinaryCareTypeCollect from "@/Domains/Registrations/Cares/TypeCollect/VeterinaryTypeCollect.vue";
import VeterinarySettings from "@/Domains/Registrations/Cares/Settings/VeterinarySettings.vue";

import AgronomicCareCauses from "@/Domains/Registrations/Cares/Cause/AgronomicCauses.vue";
import AgronomicCareIndicators from "@/Domains/Registrations/Cares/Indicator/AgronomicIndicator.vue";
import AgronomicCareService from "@/Domains/Registrations/Cares/Service/AgronomicService.vue";
import AgronomicCareRecommendation from "@/Domains/Registrations/Cares/Recommendation/AgronomicRecommendation.vue";
import AgronomicCareTypeCollect from "@/Domains/Registrations/Cares/TypeCollect/AgronomicTypeCollect.vue";
import AgronomicSettings from "@/Domains/Registrations/Cares/Settings/AgronomicSettings.vue";

import VisitCareCauses from "@/Domains/Registrations/Cares/Cause/VisitCauses.vue";
import VisitCareIndicators from "@/Domains/Registrations/Cares/Indicator/VisitIndicator.vue";
import VisitCareService from "@/Domains/Registrations/Cares/Service/VisitService.vue";
import VisitCareRecommendation from "@/Domains/Registrations/Cares/Recommendation/VisitRecommendation.vue";
import VisitCareTypeCollect from "@/Domains/Registrations/Cares/TypeCollect/VisitTypeCollect.vue";
import VisitSettings from "@/Domains/Registrations/Cares/Settings/VisitSettings.vue";

import KmRange from "@/Domains/Registrations/KmRange/Index.vue";
import ProductGroup from "@/Domains/Registrations/ProductGroup/Index.vue";
import Product from "@/Domains/Registrations/Product/Index.vue";
import Stock from "@/Domains/Registrations/Stock/Index.vue";

//Tabela de valores para técnicos / produtores / produtos
import ProjectsFinanceTechnicalPrice from "@/Domains/Financial/Prices/TechnicalPrice.vue";
import ProjectsFinanceProducerPrice from "@/Domains/Financial/Prices/ProducerPrice.vue";

import ProjectsFinanceTechnicalAccount from "@/Domains/Financial/AccountClosing/AccountClosingTechnical.vue";
import ProjectsFinanceProducerAccount from "@/Domains/Financial/AccountClosing/AccountClosingProducer.vue";

//Projetos Sanidade
import ProjectsSanityBrucellosisReport from "@/Domains/Projects/HealthExamination/SanityReportBrucellosis.vue";
import ProjectsSanityTuberculosisReport from "@/Domains/Projects/HealthExamination/SanityReportTuberculosis.vue";
import ProjectsSanityVaccinesReport from "@/Domains/Projects/HealthExamination/SanityReportVaccines.vue";
import ProjectsSanityInventoryReport from "@/Domains/Projects/HealthExamination/SanityInventoryReport.vue";
import ProjectsSanityCertificateConformityReport from "@/Domains/Projects/HealthExamination/SanityReportCertificateConformity.vue";
import ProjectsSanityTuberculosisExamsRegister from "@/Domains/Projects/HealthExamination/SanityExamsRegisterTuberculosis.vue"
import ProjectsSanityBrucellosisExamsRegister from "@/Domains/Projects/HealthExamination/SanityExamsRegisterBrucellosis.vue"
import ProjectsSanityBrucellosisGraphics from "@/Domains/Projects/HealthExamination/SanityGraphicsBrucellosis.vue";
import ProjectsSanityTuberculosisGraphics from "@/Domains/Projects/HealthExamination/SanityGraphicsTuberculosis.vue";
import ProjectsSanityVaccinesGraphics from "@/Domains/Projects/HealthExamination/SanityGraphicsVaccines.vue";
import ProjectsSanityInventoryGraphics from "@/Domains/Projects/HealthExamination/SanityGraphicsInventory.vue";
import ProjectsSanityBrucellosisExpirationSettings from "@/Domains/Projects/HealthExamination/SanityExpirationSettingsBrucellosis.vue";
import ProjectsSanityTuberculosisExpirationSettings from "@/Domains/Projects/HealthExamination/SanityExpirationSettingsTuberculosis.vue";
import ProjectsSanityVaccinesExpirationSettings from "@/Domains/Projects/HealthExamination/SanityExpirationSettingsVaccines.vue";
import ProjectsSanityCertificateConformityExpirationSettings from "@/Domains/Projects/HealthExamination/SanityExpirationSettingsCertificateConformity.vue";
import ProjectsSanityTuberculosisNormativeSettings from "@/Domains/Projects/HealthExamination/SanityNormativeSettingsTuberculosis.vue";
import ProjectsSanityBrucellosisNormativeSettings from "@/Domains/Projects/HealthExamination/SanityNormativeSettingsBrucellosis.vue";
import ProjectsSanitySettingsBrucellosis from "@/Domains/Projects/HealthExamination/SanitySettingsBrucellosis.vue";
import ProjectsSanitySettingsTuberculosis from "@/Domains/Projects/HealthExamination/SanitySettingsTuberculosis.vue";
import ProjectsSanitySettingsVaccines from "@/Domains/Projects/HealthExamination/SanitySettingsVaccines.vue";
import ProjectsSanitySettingsInventory from "@/Domains/Projects/HealthExamination/SanitySettingsInventory.vue";
import ProjectsSanitySettingsCertificateConformity from "@/Domains/Projects/HealthExamination/SanitySettingsCertificateConformity.vue";
import ProjectsSanityVaccinesRegister from "@/Domains/Projects/HealthExamination/SanityVaccinesRegister.vue";
import ProjectsSanityVaccinesEntry from "@/Domains/Projects/HealthExamination/SanityVaccinesEntry.vue";
import ProjectsSanityCertificateConformityRegister from "@/Domains/Projects/HealthExamination/SanityCertificateConformityRegister.vue";
import ProjectsSanityCertificateConformityGraphics from "@/Domains/Projects/HealthExamination/SanityGraphicsCertificateConformity.vue";
import ProjectsSanityInventoryAnimalsType from "@/Domains/Projects/HealthExamination/SanityInventoryAnimalsType.vue";
import ProjectsSanityGeneralSettings from "@/Domains/Projects/HealthExamination/SanityGeneralSettings.vue";
import ProjectsSanityReleasesReport from "@/Domains/Projects/HealthExamination/SanityExamsReleasesReport.vue";
import ProjectsSanityReleaseComparisonReport from "@/Domains/Projects/HealthExamination/SanityReleaseComparisonReport.vue";
import ProjectsSanityReleaseComparisonSettings from "@/Domains/Projects/HealthExamination/SanityReleaseComparisonSettings.vue";
import ProjectsSanityTechnicalReport from "@/Domains/Projects/HealthExamination/SanityTechnicalReport.vue";

//Projetos Visitas Definidas
import ProjectsVisitsDefinedPqflReport from "@/Domains/Projects/Visits/VisitsDefined/VisitsDefinedPqfl.vue";


export default {
  ColetaItinerario,
  ColetaRota,
  ColetaVisitaTecnico,
  ColetaSimulador,
  ColetaVolume,
  ColetaCadastroSilo,
  ColetaTotalLeite,
  ColetaTotalLeiteTecnico,
  ColetaMediaQualidadeMes,
  ColetaAntibioticos,
  ErrorLogsAnalysis,
  ColetaAnalisesProdutor,
  ColetaAnalisesSilo,
  ColetaAnalisesTanque,
  ColetaImpactoVolume,
  Bonificacao,
  BonusCriteria,
  BonusDifferences,
  OtherMilkBonus,
  OtherBonusCriteria,
  QualityIndicatorsVisits,
  ScheduledRecommendations,
  VisitaItinerario,
  TransferenciaItinerario,
  SpotItinerario,
  ItineraryLogReport,
  ActiveProducers,
  UnlinkedProducer,
  SuspendedProducer,
  PendingProducers,
  OwnershipChange,
  FreightPayment,
  FreightValueTable,
  FreightMonthPayment,
  FreightTransfersPayment,
  FreightMapReport,
  DailyCollection,
  ProducerWithoutAnalysis,
  OtherActivities,
  ProducerProspections,
  MediaAnalise,
  CooperativeRegistration,
  RankingPqfl,
  PlatformUnload,
  Spots,
  AverageOfQuality,
  ItineraryWaitingQueue,
  CollectionsPqlf,
  Settings,
  CollectionVolumeCity,
  CollectionVolumeRegion,
  RegionProducerReport,
  ProducerNotCollected,
  DiffMeasuredVale,
  DiffMeasuredValeByRoute,
  SiloScreening,
  DiscardNonconformities,
  CooperativeVolume,
  CollectionImpediment,
  Mapa,
  VisitsQuestionnaires,
  OthersQuestionnaires,
  Notifications,
  ProducerCollected,
  CollectionVolumeByRoute,
  Schedule,
  ScheduledVisited,
  VisitPayment,
  UploadAnalysis,
  SampleOrderControl,
  SampleOrderControlReport,
  QualityOutOfNormative,
  QualityCbtAverage,
  QualityCbtCritical,
  QualityPriorityRanking,
  QualityStandard,
  FleetRegistration,
  SupplierRegistration,
  CustomerRegistration,
  DairyRegistration,
  CollectionMilk,
  ProjectPmlsProducer,
  ProjectPmlsFinancial,
  ProjectPmlsChecklist,
  ProjectPqflPae,
  ProjectPqflBpa,
  ProjectPqflChecklist,
  ProjectPqflVisits,
  HealthExamination,
  PqflReport,
  SettingsMilkup,
  Dashboard,
  UserRegistration,
  VolumeRejected,
  CollectionNormative,
  AnalysisPeriodic,
  DiagnosticRecord,
  PeriodicSiloAnalysis,
  Item,
  Warehouse,
  StorageControl,
  ShippingItineraries,
  Shipment,
  ShipmentStockControl,
  ShipmentStockTracking,
  LotStockControl,
  ProducersOrders,
  BusinessOrders,
  BillsToPay,
  BillsToReceive,
  ProducerVouchers,
  ProducerCovenants,
  CashFlow,
  ProducersValeDiscounts,
  OtherProducersValeDiscounts,
  FreighterVouchers,
  PlatformProducerWaterDiscount,
  RawMaterialReport,
  CommercialMap,
  CommercialItinerary,
  CommercialRoutes,
  RegionRegistration,
  ProductStock,
  CommercialDelivery,
  SalesCommission,
  RoutineAnalysis,
  PriorityVisits,
  FleetVolume,
  VolumeProductionDeviation,
  CondominiumVolume,
  CommunityTankVolume,
  FamilyGroupVolume,
  CondominiumRegistration,
  VisitsGoals,
  RoutesMapReport,
  ProducerRating,
  PqflChecklistReport,
  PqflBpaReport,
  PriceTable,
  FinancialSettings,
  CompetingDairyRegistration,
  FleetMaintenance,
  MilkDiscounts,
  DocumentsRegistration,
  CommunityTankRegistration,
  FamilyGroupRegistration,
  AnalysisTypeRegistration,
  AnalysisProductRegistration,
  LaboratoryRegistration,
  ProductionStepRegistration,
  ProducerDiscounts,
  ProducerStatement,
  SupplierStatement,
  ControlExpenses,
  DriverExpenses,
  ExpensesByItem,
  MilkCost,
  ReportSuspendedProducers,
  ProductionOrder,
  ProductionOrderIndicators,
  ProductionPlanning,
  OutgoingInvoice,
  IncomingInvoice,
  NfpeInvoice,
  ProducersNfpeReport,
  Audit,
  ProducerTankAnalysis,
  SiloHistoryFlow,
  UnitTransfersReport,
  HealthExamReport,
  PriorityVolumeImpact,
  ProducerRatingGoals,
  DairyQualityGoals,
  DairyVisits,
  ProducerRatingIndicators,
  DairyVisitsGoals,
  ProducerEndPeriodBonus,
  ProjectDairyChecklist,
  TinaIndex,
  CryoscopyIndex,
  VeterinaryCareCauses,
  VeterinaryCareIndicators,
  VeterinaryCareService,
  VeterinaryCareRecommendation,
  VeterinaryCareTypeCollect,
  AgronomicCareCauses,
  AgronomicCareIndicators,
  AgronomicCareService,
  AgronomicCareRecommendation,
  AgronomicCareTypeCollect,
  VeterinarySettings,
  AgronomicSettings,
  ProjectPmlsVisits,
  AwardPoints,
  SpotsPlannedRealized,
  VisitCareCauses,
  VisitCareIndicators,
  VisitCareService,
  VisitCareRecommendation,
  VisitSettings,
  VisitCareTypeCollect,
  ProjectsFinanceTechnicalPrice,
  ProjectsFinanceProducerPrice,
  KmRange,
  ProductGroup,
  Product,
  Stock,
  ProjectsFinanceTechnicalAccount,
  ProjectsFinanceProducerAccount,
  AnalysisPreValidation,
  ProjectCompativeEvolution,
  FamilyGroupRegistrationProject,
  ProjectPmlsReport,
  ProjectsSanityBrucellosisReport,
  ProjectsSanityTuberculosisReport,
  ProjectsSanityVaccinesReport,
  ProjectsSanityTuberculosisExamsRegister,
  ProjectsSanityBrucellosisExamsRegister,
  ProjectsSanityBrucellosisGraphics,
  ProjectsSanityTuberculosisGraphics,
  ProjectsSanityVaccinesGraphics,
  ProjectsSanityInventoryGraphics,
  ProjectsSanityBrucellosisExpirationSettings,
  ProjectsSanityTuberculosisExpirationSettings,
  ProjectsSanityVaccinesExpirationSettings,
  ProjectsSanityTuberculosisNormativeSettings,
  ProjectsSanityBrucellosisNormativeSettings,
  ProjectsSanitySettingsBrucellosis,
  ProjectsSanitySettingsTuberculosis,
  ProjectsSanitySettingsVaccines,
  ProjectsSanitySettingsInventory,
  ProjectsSanityVaccinesRegister,
  ProjectsSanityInventoryReport,
  ProjectsSanityCertificateConformityReport,
  ProjectsSanityVaccinesEntry,
  ProjectsSanityCertificateConformityRegister,
  ProjectsSanityCertificateConformityGraphics,
  ProjectsSanitySettingsCertificateConformity,
  ProjectsSanityCertificateConformityExpirationSettings,
  ProjectsSanityInventoryAnimalsType,
  ProjectsSanityGeneralSettings,
  ProjectsSanityReleasesReport,
  ProjectsSanityReleaseComparisonReport,
  ProjectsSanityReleaseComparisonSettings,
  ProjectsSanityTechnicalReport,
  ProjectsVisitsDefinedPqflReport,
};
