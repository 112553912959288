<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Autorizar Lote de Notas Fiscais Eletrônicas</span>
        <v-spacer />
        <v-btn
          v-if="isCloseVisible"
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>Autorização de NF-e</v-card-subtitle>

      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="6">
            <div class="font-weight-bold">
              Quantidade de Notas
            </div>
            <div class="text-h6 secondary--text">
              {{ invoices.length }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-timeline dense>
              <v-slide-x-reverse-transition
                group
                hide-on-leave
              >
                <v-timeline-item
                  v-if="isHomolog"
                  key="ambiente"
                  color="warning"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    type="warning"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          Ambiente de Homologação
                        </div>
                        <div class="body-1">
                          Documento sem valor fiscal
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
                <v-timeline-item
                  v-if="started.show"
                  key="auth"
                  :color="started.type"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    :type="started.type"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <template #prepend>
                      <v-progress-circular
                        v-if="started.loading"
                        indeterminate
                        color="info"
                      />
                    </template>
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          {{ started.text }}
                        </div>
                        <div
                          v-if="started.detail"
                          class="body-1"
                        >
                          {{ started.detail }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
                <v-timeline-item
                  v-if="checking.show"
                  key="status"
                  :color="checking.type"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    :type="checking.type"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <template #prepend>
                      <v-progress-circular
                        v-if="checking.loading"
                        indeterminate
                        color="info"
                      />
                    </template>
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          {{ checking.text }}
                        </div>
                        <div
                          v-if="checking.detail"
                          class="body-1"
                          v-html="checking.detail"
                        />
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
              </v-slide-x-reverse-transition>
            </v-timeline>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="isCloseVisible"
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!started.show"
          outlined
          color="primary"
          @click="authorize()"
        >
          Autorizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {

  data() {
    return {
      dialog: false,

      invoices: [],

      isHomolog: false,
      started: {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      },
      checking: {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      },
      tryNumber: 0,

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        length: len => v => (v || '').length >= len || 'Justificativa muito curta',
      },
    }
  },

  computed: {
    isCloseVisible() {
      return !this.started.show || this.checking.show || this.started.type === 'error' || this.checking.type === 'error';
    }
  },

  methods: {
    show(invoices) {
      this.reset();
      this.isHomolog = invoices[0].ambiente === 2;
      this.invoices = invoices;
      this.dialog = true;
    },

    reset() {
      this.isHomolog = false;
      this.started = {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      };
      this.checking = {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      };
      this.tryNumber = 0;
    },

    close() {
      this.$emit('close');
      this.dialog = false;
    },

    async authorize() {
      if (this.started.show) {
        return;
      }

      const ids = this.invoices
        .filter(item => item.status === 'PENDENTE')
        .map(item => item.id);

      if (ids.length === 0) {
        return;
      }

      this.started.show = true;
      this.started.loading = true;
      this.started.type = 'info';
      this.started.text = `Enviando ${ids.length} notas para a SEFAZ`;

      try {

        const { data } = await this.$axios.post(`/fiscal/invoice/authorize`, {
          ids
        });

        const hasSuccess = data.responses.some(({ result }) => result.code === 1);

        if (!hasSuccess) {
          throw data.responses[0].result.message;
        }

        this.started.loading = false;
        this.started.type = 'success';
        this.started.text = 'Enviado';

        const invoices = data.responses.flatMap(response => response.invoices);

        this.handleStatusResult(invoices);

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);

        this.started.loading = false;
        this.started.type = 'error';
        this.started.text = 'Erro ao autorizar nota';
        this.started.detail = message;
      }
    },

    async checkStatus() {
      const ids = this.invoices
        .filter(item => item.status === 'PROCESSANDO')
        .map(item => item.id);

      if (ids.length === 0) {
        return;
      }

      try {
        const { data } = await this.$axios.post(`/fiscal/invoice/status`, {
          ids
        });

        const hasSuccess = data.responses.some(({ result }) => result.code === 1);

        if (!hasSuccess) {
          throw data.responses[0].result.message;
        }

        const invoices = data.responses.map(response => response.invoice);

        this.handleStatusResult(invoices);

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);

        this.checking.loading = false;
        this.checking.type = 'error';
        this.checking.text = 'Erro ao consultar status';
        this.checking.detail = message;
      }
    },

    async handleStatusResult(invoices) {
      // Quando tiver notas processadas, zera o contador de tentativas
      if (invoices.find(invoice => invoice.status !== 'PROCESSANDO')) {
        this.tryNumber = 0;
      }

      for (let invoice of invoices) {
        const idx = this.invoices.findIndex(item => item.id === invoice.id);
        this.invoices.splice(idx, 1, invoice);
      }

      const emitidas = this.invoices.filter(invoice => invoice.status === 'EMITIDA').length;
      const processando = this.invoices.filter(invoice => invoice.status === 'PROCESSANDO').length;
      const rejeitadas = this.invoices.length - emitidas - processando;

      let detail = '';

      if (processando > 0) {
        detail += `<span class='d-block blue--text'>${processando} notas em processamento</span>`;
      }

      if (emitidas > 0) {
        detail += `<span class='d-block green--text'>${emitidas} notas emitidas</span>`;
      }

      if (rejeitadas > 0) {
        detail += `<span class='d-block red--text'>${rejeitadas} notas rejeitadas</span>`;
      }

      if (processando > 0) {
        this.tryNumber++;

        if (this.tryNumber >= 5) {
          detail += `Não foi possível obter o retorno da SEFAZ. Tente novamente mais tarde.`;
          this.checking.loading = false;
          this.checking.type = 'warning';
          this.checking.text = 'Processando';
          this.checking.detail = detail;
          return;
        }
        else {
          this.checking.show = true;
          this.checking.loading = true;
          this.checking.type = 'info';
          this.checking.text = `Processando [${this.tryNumber}]`;
          this.checking.detail = detail;

          // Delay para consultar novamente o status do processamento
          await this.sleep(3000);

          this.checkStatus();
        }
      } else {
        this.checking.show = true;
        this.checking.loading = false;
        this.checking.type = 'success';
        this.checking.text = 'Processado';
        this.checking.detail = detail;
      }
    },

    async sleep(delay) {
      return new Promise(function(resolve) {
        setTimeout(resolve, delay);
      })
    },

  },

}
</script>
