<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Média Volume (por Estado)</span>
      <v-spacer />
      <span class="caption font-weight-light">
        {{ formatDate(period[0]) }} - {{ formatDate(period[1]) }}
      </span>
      <v-menu
        v-model="showDatePicker"
        :close-on-content-click="false"
        offset-y
        bottom
        left
        content-class="menu-select"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            small
            v-on="on"
          >
            <v-icon>expand_more</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          v-model="period"
          no-title
          range
          @change="onChangeDateRange"
        />
      </v-menu>
    </v-card-title>

    <v-tabs
      v-show="collected.length > 1"
      v-model="tab"
      align-with-title
      background-color="transparent"
      class="transparent flex-grow-0"
      dark
    >
      <v-tabs-slider color="cyan" />

      <v-tab><v-icon>analytics</v-icon></v-tab>
      <v-tab><v-icon>list</v-icon></v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="flex-grow-1 milk-average-tabs-items"
    >
      <v-tab-item>
        <template v-if="collected.length > 1">
          <div class="d-flex flex-column mx-4">
            <span class="subtitle font-weight-light">
              Média Coletado: {{ formatNumber(averageCollected) }} L
            </span>
          </div>
          <v-chart
            type="pie"
            :options="optionsCollected"
            :style="{width: '100%', height: 'calc(100% - 30px) !important'}"
            autoresize
          />
        </template>
        <div
          v-else
          class="flex-grow-1 d-flex flex-column mt-4 mb-6"
        >
          <span class="display-2 font-weight-bold mt-16 cyan--text">
            {{ formatNumber(averageCollected) }} L
          </span>
          <span class="mb-16">
            Média Coletado
          </span>
          <span v-if="collected.length === 1">
            {{ collected[0].name }}
          </span>
        </div>
      </v-tab-item>

      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="collected"
          dark
          class="elevation-1 report-table"
          dense
          hide-default-footer
          disable-pagination
        >
          <template #[`item.value`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`body.append`]="{}">
            <tr
              v-if="collected.length > 0"
              class="hidden-xs-only"
            >
              <td class="text-start cell-border">
                <b>Média</b>
              </td>
              <td class="text-center subtitle-1">
                <b>{{ formatNumber(averageCollected) }}</b>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<style lang="scss">
.cell-border {
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.milk-average-tabs-items {
  .v-window__container, .v-window-item {
    height: 100%;
  }

  > div {
    overflow-y: auto;

    .v-data-table {
      flex-grow: 1 !important;

      .v-data-table__wrapper {
        height: 100%;
        background: rgba(255, 255, 255, 0.08);
      }
    }
  }
}
</style>

<script>
import moment from 'moment';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '250px',
    },
  },

  data() {
    return {
      loading: false,
      tab: null,
      collected: [],
      period: [moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
      showDatePicker: false,
      headers: [
        { text: 'Estado', value: 'name', cellClass: 'cell-border' },
        { text: 'Média (L)', value: 'value', align: 'center' },
      ],
    }
  },

  computed: {
    optionsCollected() {
      return {
        color: [
          'rgba(38, 198, 218, 0.7)',
          'rgba(171, 71, 188, 0.7)',
          'rgba(255, 255, 0, 0.7)'
        ],
        tooltip: {
          trigger: 'item',
          formatter: (param) => `${param.marker} ${param.name} <b>${this.formatNumber(param.value)} L</b>`,
        },
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: '#ddd',
          }
        },
        series: [
          {
            name: 'Volume',
            type: 'pie',
            labelLine: { show: false },
            label: {
              show: true,
              position: 'inside',
              formatter: ({ value }) => this.formatNumber(value),
              textShadowColor: '#000',
              textShadowBlur: 0.5,
            },
            top: -40,
            radius: '60%',
            roseType: 'radius',
            data: this.collected,
          }
        ],
      }
    },

    averageCollected() {
      if (this.collected.length === 0) {
        return 0
      }
      if (this.collected.length === 1) {
        return this.collected[0].value
      }
      return this.collected.reduce((acc, cur) => acc + cur.value, 0) / this.collected.length
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    onChangeDateRange() {
      this.showDatePicker = false
      this.loadReport()
    },
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data: { relatorio } } = await this.$axios.post(
          `/relatorios/mediaLeitePorEstado`,
          { data_inicio: startDate, data_fim: endDate }
        );

        this.collected = relatorio.map(o => ({
          name: o.estado,
          value: parseInt(o.volume)
        }));

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de Média Volume (por Estado)!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatDate: date => moment(date).format('DD/MM/YYYY'),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 0 }).format(value),
  }
}
</script>
