<template>
  <div>
    <visits-defined
      :module="module"
    >

    </visits-defined>
  </div>
</template>

<script>
import VisitsDefined from "@/Domains/Projects/Visits/VisitsDefined/Index.vue";

export default {
  name: "VisitsDefinedPqfl",

  components: {
    VisitsDefined,
  },

  data() {
    return {
      module: "pqfl",
    };
  },
};
</script>

