<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Produtores com Emissão NFP-e
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-menu
                  ref="menu"
                  transition="scale-transition"
                  offset-y
                  content-class="menu-select"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      :value="selectedMonth"
                      label="Mês de Referência"
                      prepend-inner-icon="event"
                      readonly
                      dark
                      filled
                      hide-details
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="filter.date"
                    :max="currentMonth"
                    type="month"
                    no-title
                    scrollable
                    @change="loadReport"
                  />
                </v-menu>
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  filled
                  single-line
                  hide-details
                  dark
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            ref="report"
            :headers="headers"
            :items="items"
            :search="filter.search"
            dark
          >
            <template #[`item.tipo_cobranca`]="{ value }">
              <v-chip small>
                {{ getIssueType(value) }}
              </v-chip>
            </template>
            <template #[`item.valor`]="{ value }">
              {{ formatCurrency(value) }}
            </template>
            <template #[`item.status_cobranca`]="{ value }">
              <v-chip
                small
                :color="value === 'PENDENTE' ? 'red' : 'success'"
              >
                {{ value }}
              </v-chip>
            </template>

            <template #[`footer.prepend`]>
              <div class="text-caption text-left ml-4">
                Total Emitido:
                <v-chip
                  small
                  class="mx-2 mb-0"
                >
                  {{ formatCurrency(totalIssued) }}
                </v-chip>
              </div>
              <div class="text-caption text-left ml-4">
                Total Pendente:
                <v-chip
                  small
                  class="mx-2 mb-0"
                >
                  {{ formatCurrency(totalPending) }}
                </v-chip>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, computed, reactive } from 'vue'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

const { progressBar, notify } = useUtils()

const currentMonth = moment().format('YYYY-MM')

const filter = reactive({
  date: currentMonth,
  search: null,
})

const selectedMonth = computed(() => isEmpty(filter.date) ? null : moment(filter.date, 'YYYY-MM').format('MM/YYYY'))

const headers = [
  { text: 'Cód. Latícinio', value: 'codigo_laticinio' },
  { text: 'Nome', value: 'nome' },
  { text: 'Status', value: 'status' },
  { text: 'Grupo', value: 'nome_condominio' },
  { text: 'Tipo', value: 'tipo_cobranca' },
  { text: 'Valor', value: 'valor' },
  { text: 'Cobrança', value: 'status_cobranca' },
]

const report = ref()
const items = ref([])

const totalIssued = computed(() => items.value.reduce((acc, item) => item.status_cobranca !== 'PENDENTE' ? (acc + item.valor) : acc, 0))
const totalPending = computed(() => items.value.reduce((acc, item) => item.status_cobranca === 'PENDENTE' ? (acc + item.valor) : acc, 0))

const loadReport = async () => {
  try {
    progressBar?.loading()

    const { data } = await axios.get(`/fiscal/invoice/nfpe-report`, { params: {
      month_reference: filter.date,
    } })

    items.value = data

  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar?.hide()
  }
}

const getIssueType = (value) => {
  const types = {
    'NOTA_E_CERT': 'EMISSÃO NOTA + CERTIFICADO',
    'NOTA': 'EMISSÃO NOTA',
    'NOTA_E_CERT_GRUPO': 'EMISSÃO NOTA + CERTIFICADO (GRUPO)',
  }

  return types[value] || '-'
}

const formatCurrency = (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)

loadReport()
</script>
